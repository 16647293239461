import { IconButton } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { setLaborErrorMode } from "../../../../../Redux/laborReducer";


export interface CommissionRate {
    id: number;
    lowerMargin: number;
    upperMargin: number;
    rate: number;
    creationDate: string;
}

function CurrentCommissionRatesRow({
    rate,
    lineNumber,
    onChange,
}: {
    rate: CommissionRate;
    lineNumber: number;
    onChange: (updated: CommissionRate) => void;
}) {
    const { lowerMargin, upperMargin, rate: commissionRate } = rate;
    const [error, setError] = useState<string>("")

    // Convert to percentage for display
    const displayLower = (lowerMargin * 100).toString();
    const displayUpper = (upperMargin * 100).toString();
    const displayRate = (commissionRate * 100).toString();


    const originalLowerMargin = displayLower;
    const [baseLowerMargin, setBaseLowerMargin] = useState(originalLowerMargin)
    function onResetLowerMargin() { setBaseLowerMargin(originalLowerMargin) }
    const baseLowerMarginChanged = baseLowerMargin !== originalLowerMargin;

    const originalUpperMargin = displayUpper;
    const [baseUpperMargin, setBaseUpperMargin] = useState(originalUpperMargin)
    function onResetUpperMargin() { setBaseUpperMargin(originalUpperMargin) }
    const baseUpperMarginChanged = baseUpperMargin !== originalUpperMargin;

    const originalRate = displayRate;
    const [baseRate, setBaseRate] = useState(originalRate);
    function onResetRate() { setBaseRate(originalRate) }
    const baseRateChanged = baseRate !== originalRate;

    const rowChangesPresent = baseRateChanged || baseUpperMarginChanged || baseLowerMarginChanged


    function handleLowerMarginChange(val: string) {
        const lower = parseFloat(val) || 0;
        if (lower >= rate.upperMargin * 100) {
            setError("Lower margin must be less than Upper margin");
            return;
        } else {
            setError("");
        }
        onChange({ ...rate, lowerMargin: lower / 100 });
       
    }
    function handleUpperMarginChange(val: string) {
        const upper = parseFloat(val) || 0;
        onChange({ ...rate, upperMargin: upper / 100 });
    }

    function handleRateChange(val: string) {
        const num = parseFloat(val) || 0;
        onChange({ ...rate, rate: num / 100 });
    }

   

    return (
        <>
        <tr>
               <td>{lineNumber}</td>
                <td align="right">
                    <div className="flex-row">
                        <NumberFormat
                            value={displayLower} onValueChange={(v) => handleLowerMarginChange(v.value)}
                            className="w-5r"
                            displayType="input" allowNegative={false}
                            decimalScale={0} 
                            suffix="%"

                        />
                        <IconButton onClick={onResetLowerMargin} style={{ visibility: baseLowerMarginChanged ? "visible" : "hidden" }} className="padding-vertical-none">
                        <CancelIcon/>
                        </IconButton>

                    </div>
                    
                </td>
                <td align="left"> to </td>
                <td align="right">
                    <div>
                        <NumberFormat
                            value={displayUpper} onValueChange={(v) => handleUpperMarginChange(v.value)}
                            className="w-5r"
                            displayType="input" allowNegative={false}
                            decimalScale={0}
                            suffix="%"
                        />
                        <IconButton onClick={onResetUpperMargin} style={{ visibility: baseUpperMarginChanged ? "visible" : "hidden" }} className="padding-vertical-none">
                            <CancelIcon/>
                        </IconButton>
                    </div>
                    
                </td>
                <td align="center">
                    <div>
                        <NumberFormat
                            value={displayRate} onValueChange={(v) => handleRateChange(v.value)}
                            className="w-5r"
                            displayType="input" allowNegative={false}
                            decimalScale={0}
                            suffix="%"
                        />
                        <IconButton onClick={onResetRate} style={{ visibility: baseRateChanged ? "visible" : "hidden" }} className="padding-vertical-none">
                            <CancelIcon/>
                        </IconButton>
                    </div>
                    

                
                </td>    
                <td>
                    {error && <div style={{ color: "red" }}>{error}</div>}
                </td>
            </tr>
        </>
    );
}

export default CurrentCommissionRatesRow;
