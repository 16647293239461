import  { useEffect, useState } from "react";
import { useArchiveAndInsertNewRatesMutation, useGetCurrentCommissionRatesQuery } from "../../../../../generated/graphql";
import CurrentCommissionRatesRow, { CommissionRate } from "./CurrentCommissionRatesRow";
import NavbarPage from "../../../../../Components/Page";
import { Button } from "@material-ui/core";
import NewCurrentCommissionRates from "./NewCurrentCommissionRate";
import { Link, useRouteMatch } from "react-router-dom";


export default function CurrentCommissionRatesPage() {
    const { data, loading, error } = useGetCurrentCommissionRatesQuery();
    const [localRates, setLocalRates] = useState<CommissionRate[]>([]);
    const [originalRates, setOriginalRates] = useState<CommissionRate[]>([]);
    const currentDate = new Date().toLocaleDateString();
    const { path } = useRouteMatch();


    useEffect(() => {
        if (data?.currentCommissionRates) {
            setLocalRates(data.currentCommissionRates as CommissionRate[]);
            setOriginalRates(data.currentCommissionRates as CommissionRate[]);
        }
    }, [data]);
    
    const [archiveAndInsertNewRates, { loading: saving }] =
        useArchiveAndInsertNewRatesMutation({
            refetchQueries: ["GetCurrentCommissionRates"],
        });

    // 5. Handler to save all updated rows at once
    function handleUpdateAllRows() {

        if (areRatesEqual(localRates, originalRates)) {
            alert("No changes to save !!!")
            return;
        }
        archiveAndInsertNewRates({
            variables: {
                // localRates is an array of CommissionRate objects
                // that might need to be converted to the input type if different
                input: localRates.map((r) => ({
                    
                    lowerMargin: r.lowerMargin,
                    upperMargin: r.upperMargin,
                    rate: r.rate,
                    creationDate: currentDate,
                })),
            },
        })
            .then(() => {
                console.log("All rows saved successfully");
            })
            .catch((err) => {
                console.error("Error saving rows:", err);
            });
    }
    function areRatesEqual(a: CommissionRate[], b: CommissionRate[]) {
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; i++) {
            if (
                a[i].id !== b[i].id ||
                a[i].lowerMargin !== b[i].lowerMargin ||
                a[i].upperMargin !== b[i].upperMargin ||
                a[i].rate !== b[i].rate
            ) {
                return false 
            }
        }
        return true;
    }


    const [newRowEditorOpen, setNewRowEditorOpen] = useState(false);
    

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    
    return (
        <NavbarPage centerHorizontally title="Current Commission Rates" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem", overflow:"auto" }}>
            <div className="table-fixed-new flex-column align-items-center" style={{ height: "100%", width: "fit-content" }} >               
                <table>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Line</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Lower Margin (%)</th>
                            <th style={{padding:"20px"} }></th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Upper Margin (%)</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate (%)</th>                           
                        </tr>
                    </thead>
                    <tbody>
                        {localRates.length > 0 ? (
                            localRates.map((rate, index) => (
                                rate.id != null ? ( 
                                    <CurrentCommissionRatesRow
                                        key={rate.id}
                                        rate={rate as CommissionRate}
                                        lineNumber={index + 1}
                                        onChange={(updated) => {
                                            const newRates = [...localRates];
                                            newRates[index] = updated;
                                            setLocalRates(newRates)
                                        } }
                                    />
                                ) : null
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} style={{ textAlign: "center", padding: "10px" }}>
                                    No commission rates available.
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
                <div>
                    <Button
                        variant="contained"
                        onClick={handleUpdateAllRows}
                        
                    >
                        {saving ? "Saving...":"Update Rows"}
                </Button>
                <Button
                    variant="contained"
                    className="margin-vertical-sm"
                    onClick={()=>setNewRowEditorOpen(true) }
                >
                    Add New Row
                </Button>
                {newRowEditorOpen && (
                    <NewCurrentCommissionRates
                        setOpen={setNewRowEditorOpen}
                        
                    />
                    )}
                    <Link to={`${path}/commission-history` }>
                    <Button
                        variant="contained"
                    
                    >
                        View History
                    </Button>
                    </Link>
                    
                </div>
            </div>
        </NavbarPage>
        
    );
}
