import { Button, Input, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { PopoutSignature } from "Components/Forms/Controls/PopoutSignature";
import Loading from "Components/Loading";
import NavbarPage from "Components/Page";
import {
    AreaNotesAndIdInput,
    Customer,
    JobContractPaymentInput,
    namedOperations,
    useAddContractToJobWithDepositMutation,
    useGetContractDataQuery,
    useGetJobActiveContractIdQuery,
    useGetJobIdQuery,
    useGetJobInvoiceDataQuery,
    useUpdateContractWalkInMutation,
    useUpdateCustomerDetailsMutation,
} from "generated/graphql";
import { prepareCustomer } from "Globals/dataPreparationUtils";
import { dateTimeStrToDate } from "Globals/DateAndTimeHelpers";
import { isEmptyString, isNotNullOrUndefined } from "Globals/GenericValidators";
import { buildAppendedId, useNumericIdParam } from "Globals/Hooks";
import { cartPath, originalContractPath } from "Globals/PathStrings";
import { useAppHistory } from "Globals/routingHooks";
import {
    formatNameStringFirstLast,
    formatSimpleDate,
} from "Globals/StringFormatting";
import { useEffect, useMemo, useRef, useState } from "react";
import { AreaInvoiceRow } from "./AreaInvoiceRow";
import ContractSuccessDialog from "./ContractSuccessDialog";
import countBusinessDaysFrom, {
    depositMethodIdToString,
    depositMethodStringToId,
} from "./InvoiceData";
import InvoiceDocumentHeader from "./InvoiceDocumentHeader";
import {
    InvoiceAdditionalTermsAndConditions,
    InvoiceContractTermsAndConditions,
    InvoiceNoticeOfCancellationText,
    InvoiceNoticeOfRightToCancelText,
} from "./InvoicePageText";
import "./InvoiceStyling.css";
import {
    calculateInvoiceTotal,
    InvoicePaymentData,
    InvoiceTotal,
    PaymentMethodTypes,
} from "./InvoiceTotal";
import UpdateCustomerDetailDialog from "./UpdateCustomerDetailDialog";

const FIN_ACC_NUM_REQ_LENGTH = 16;

export function STd({
    className,
    ...props
}: React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
>) {
    return (
        <td {...props} className={clsx("solid-border padding-side-xsm", className)} />
    );
}

export default function Invoice() {
    const history = useAppHistory();

    const { id: jobConfigurationId, print: isPrintMode } = useNumericIdParam();

    const { data: jobIdData } = useGetJobIdQuery({
        skip: !jobConfigurationId,
        variables: { jobConfigurationId: jobConfigurationId! },
    });
    const jobId = jobIdData?.jobId ?? -1;

    useGetJobActiveContractIdQuery({
        variables: { jobId: jobId! },
        skip: jobId < 1 || isPrintMode,
        onCompleted: (data) => {
            const contractId = data.jobActiveContractId;
            if (contractId > 0) {
                history.push(`${originalContractPath}/${contractId}`);
            }
        },
    });

    function goToCart() {
        if (window.confirm("Any changes will not be saved. Continue?")) {
            history.push(cartPath + buildAppendedId(jobConfigurationId));
        }
    }

    const [depositData, setDepositData] = useState<InvoicePaymentData>({
        depositPayments: [],
        financingAccountNumber: "",
    });

    const [areaNotes, setAreaNotes] = useState<{ [areaId: number]: string }>({});
    const [jobNotes, setJobNotes] = useState<string>("");

    const { data, loading } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only",
        skip: (jobConfigurationId ?? 0) < 1,
        onCompleted(data) {
            const areaNotesObj: { [areaId: number]: string } = {};
            data.jobConfiguration?.areas.forEach(
                (an) => (areaNotesObj[an.id] = an.notes)
            );
            setAreaNotes(areaNotesObj);
            setJobNotes(data.jobConfiguration?.notes ?? "");
        },
    });

    const [updateCustomerDetails] = useUpdateCustomerDetailsMutation({
        refetchQueries: [namedOperations.Query.GetJobInvoiceData],
        awaitRefetchQueries: true,
    });

    const { data: signedContractDetails, loading: loadingSignedDetails } =
        useGetContractDataQuery({
            variables: { jobConfigurationId: jobConfigurationId ?? 0 },
            skip: !isPrintMode,
            onCompleted(data) {
                if (data !== null) {
                    setDepositData({
                        depositPayments: (data.contractData?.payments ?? []).map(
                            (payment) => ({
                                method: depositMethodIdToString(
                                    payment.paymentMethodId
                                ) as PaymentMethodTypes,
                                amount: payment.amount,
                                editable: true,
                            })
                        ),
                        financingAccountNumber:
                            data.contractData?.financingAccountNumber ?? "",
                    });

                    const areaNotesObj: { [areaId: number]: string } = {};
                    data.contractData?.updatedAreaNotes.forEach(
                        (an) => (areaNotesObj[an.areaId] = an.areaNotes)
                    );
                    setAreaNotes(areaNotesObj);
                    setJobNotes(data.contractData?.updatedJobNotes ?? "");
                    setIsPhysicalWalkIn( data.contractData?.isWalkIn);
                }
            },
        });
    console.log(signedContractDetails);

    const [
        addContract,
        { data: contractSubmissionData, loading: submittingContract },
    ] = useAddContractToJobWithDepositMutation({
        onCompleted: (data) => {
            if (data.addContractToJobWithDeposit ?? -1 > 0) {
                setSuccessDialogOpen(true);
            } else {
                alert("Could not create contract - job already has an active contract");
            }
        },
        onError: (_) => alert("Could not create contract"),
    });

    // --- Signature refs and end-of-page ref ---
    const firstSigRef = useRef<HTMLImageElement>(null);
    const secSigRef = useRef<HTMLImageElement>(null);
    const endRef = useRef<HTMLDivElement>(null);

    const scrollToBottomOfPage = () =>
        endRef.current?.scrollIntoView({ behavior: "smooth" });

    // --- Page viewing state ---
    const [signatureLocked, setSignatureLocked] = useState(true);
    const [contractSignatureOpen, setFirstSignatureOpen] = useState(false);

    const [cancelSignatureOpen, setCancelSignatureOpen] = useState(false);

    // These control the default/legacy notice-of-cancel flow
    const [showRightToCancel, setShowRightToCancel] = useState(false);
    const [showNoticeOfCancel, setShowNoticeOfCancel] = useState(false);

    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [editCustomerOpen, setEditCustomerOpen] = useState(false);

    // ------------------------------------------------------
    // 1) Add a new state to track walk-in (physical) purchase
    // ------------------------------------------------------
    const [isPhysicalWalkIn, setIsPhysicalWalkIn] = useState(false);

    const [updateWalkIn] = useUpdateContractWalkInMutation();
    

    // If the user scrolls to the bottom of the T&C, unlock the signature
    function handleReachedBottomOfTnC() {
        if (signatureLocked) {
            setSignatureLocked(false);
            setContractSignatureOpen(true);
        }
    }

    useEffect(() => {
        // If user toggles "walk-in," we can also hide the Notice of Cancel sections
        // OR you can leave them as-is unless the user explicitly signs. But typically,
        // if it's a walk-in, you won't do the notice-of-cancel flow at all.

        if (isPhysicalWalkIn) {
            setShowRightToCancel(false);
            setShowNoticeOfCancel(false);
        }
    }, [isPhysicalWalkIn]);

    useEffect(() => {
        if (showRightToCancel) {
            scrollToBottomOfPage();
        }
    }, [showRightToCancel]);

    useEffect(() => {
        if (showNoticeOfCancel) {
            scrollToBottomOfPage();
        }
    }, [showNoticeOfCancel]);

    function setContractSignatureOpen(open: boolean) {
        // This toggles the "Notice of Right to Cancel" panel automatically if we want
        // to show it as soon as the user is done signing the contract.
        if (!isPhysicalWalkIn) {
            if (open === false && showRightToCancel === false) {
                setShowRightToCancel(true);
            }
        }
        setFirstSignatureOpen(open);
    }

    // After the second signature pad is closed, show the Notice of Cancel text
    // if the user is not physically walking in
    function handleCloseCancelSignature(open: boolean) {
        if (!isPhysicalWalkIn && open === false && showNoticeOfCancel === false) {
            setShowNoticeOfCancel(true);
        }
        setCancelSignatureOpen(open);
    }

    // Check if the first signature is present
    const hasFirstSignature = useMemo(() => {
        return !!(firstSigRef.current && firstSigRef.current.src);
    }, [firstSigRef.current?.src]);

    // Check if the second signature is present
    const hasSecondSignature = useMemo(() => {
        return !!(secSigRef.current && secSigRef.current.src);
    }, [secSigRef.current?.src]);

   /**
   * Decide if the user can process the contract:
   *  1) Must always have the first signature
   *  2) If NOT a walk-in (meaning we DO show Notice of Right to Cancel),
   *     then the second signature is required as well.
   */

    const canProcess = useMemo(() => {
        // If there's no first signature, can't process
        if (!hasFirstSignature) {
            return false;
        }
        // If it's not a walk-in (meaning we do show Notice of Cancel) but we haven't
        // captured the second signature yet, also disable the button
        if (!isPhysicalWalkIn && !hasSecondSignature) {
            return false;
        }

        // If we get here, the required signatures are present
        return true;
    }, [hasFirstSignature, hasSecondSignature, isPhysicalWalkIn]);


    // ------------------------------------------------------
    // 2) In verifyPageFilled, skip second signature if walk-in
    // ------------------------------------------------------
    function verifyPageFilled(silent?: boolean) {
        const writeError = !(silent ?? false);

        if (customer?.email === "") {
            if (writeError) window.alert("Please enter customer email");
            return false;
        }
        if (depositData.depositPayments.length === 0) {
            // your existing financing logic...
            if (hasFinancing) {
                // Allow $0 down payment for financing
                return true;
            } else if (writeError) {
                window.alert("Please select deposit payment method");
                return false;
            }
        //} else if (!hasFinancing && totalDeposit < depositAmountErrorThreshold) {
        //    if (writeError) {
        //        window.alert(
        //            "At least 50% deposit required before scheduling installation."
        //        );
        //    }
        //    return false;
        } else if (
            hasFinancing &&
            depositData.financingAccountNumber.length < FIN_ACC_NUM_REQ_LENGTH
        ) {
            if (writeError) window.alert("Please enter financing account number");
            return false;
        }

        // Must have first signature
        if (isEmptyString(firstSigRef.current?.src ?? "")) {
            if (writeError) window.alert("Please sign contract");
            return false;
        }

        // If not a walk-in, must have second signature
        if (!isPhysicalWalkIn && isEmptyString(secSigRef.current?.src ?? "")) {
            if (writeError) window.alert("Please sign notice of right to cancel");
            return false;
        }

        return true;
    }

    
    function finalizeContract() {
        const flattenedAreaNotes: AreaNotesAndIdInput[] = Object.keys(areaNotes).map(
            (key) => ({
                areaId: +key,
                areaNotes: areaNotes[+key],
            })
        );

        const payments: JobContractPaymentInput[] = depositData.depositPayments.map(
            (payment) => {
                return {
                    id: -1,
                    jobContractId: -1,
                    paymentMethodId: depositMethodStringToId(payment.method) ?? -1,
                    isForDeposit: true,
                    amount: payment.amount,
                };
            }
        );

        if (verifyPageFilled(false)) {
            addContract({
                variables: {
                    jobContractDetails: {
                        jobConfigurationId: data?.jobConfiguration?.id ?? -1,
                        contractSignatureBase64: firstSigRef.current?.src ?? "",
                        // If walk-in, do not attach a second signature
                        noticeOfRightSignatureBase64: isPhysicalWalkIn
                            ? ""
                            : secSigRef.current?.src ?? "",
                        payments: payments,
                        financingAccountNumber: depositData.financingAccountNumber,
                        updatedAreaNotes: flattenedAreaNotes,
                        updatedJobNotes: jobNotes,
                        email: customer!.email,
                    },
                },
            });
        }
    }

    if ((data ?? undefined) === undefined || loading || loadingSignedDetails) {
        return (
            <NavbarPage title="Invoice" hideNavbar>
                <Loading />
            </NavbarPage>
        );
    }

    const areas = data!.jobConfiguration.areas.filter((area) => area.includedInQuote);
    const financing = data?.jobConfiguration.financing!;
    const price = data?.jobConfiguration.price!;
    const hasFinancing = isNotNullOrUndefined(financing.financingOption);

    const depositAmnt = hasFinancing ? financing.financingOption?.depositAmount : 0;
    const symbol = hasFinancing ? financing.financingOption?.symbol : "$";

    // Only enforce the 50% deposit if not financed
    const depositAmountErrorThreshold = hasFinancing ? 0 : 0.5 * price.total;
    const totalDeposit = calculateInvoiceTotal(depositData.depositPayments);

    const customer = data?.jobConfigurationHeader.customer;
    const sahNumber = data?.jobConfigurationHeader.appointmentNumber;
    const signingDate = signedContractDetails
        ? dateTimeStrToDate(signedContractDetails!.contractData.signingDate)
        : new Date();
    const endDate = countBusinessDaysFrom(signingDate, 3);

    return (
        <div className="padding-xsm" style={{ maxWidth: "80rem", margin: "0 auto" }}>
            <InvoiceDocumentHeader
                documentTitle="Conditional Sales Contract"
                sahOrQuoteNumber={isPrintMode ? sahNumber : undefined}
                {...customer!}
                date={signingDate}
                salesperson1={data?.jobConfigurationHeader.assignedSalespeople?.[0]}
                salesperson2={data?.jobConfigurationHeader.assignedSalespeople?.[1]}
                onEdit={() => setEditCustomerOpen(true)}
                renderExpanded={isPrintMode}
            />

            {customer?.email === "" && (
                <Typography color="error" style={{ marginBottom: ".5rem" }}>
                    Add email before submitting
                </Typography>
            )}

            

            <table className="margin-bottom-sm fill-width">
                <tbody>
                    {areas.map((area) => {
                        return (
                            <AreaInvoiceRow
                                key={`area-index-${area.id}`}
                                area={area}
                                notes={areaNotes?.[area.id] ?? ""}
                                onChangeNotes={(value) =>
                                    setAreaNotes({ ...areaNotes, [area.id]: value })
                                }
                                printMode={isPrintMode}
                            />
                        );
                    })}
                </tbody>
            </table>
            {/* ------------------------------------------------------ */}
            {/* 3) Checkbox to track if it's a physical walk-in purchase */}
            {/* ------------------------------------------------------ */}
            {/*{!isPrintMode && (*/}
            {/*    <div style={{ marginBottom: "1rem" }}>*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox*/}
            {/*                    checked={isPhysicalWalkIn}*/}
            {/*                    onChange={(e) => setIsPhysicalWalkIn(e.target.checked)}*/}
            {/*                    color="primary"*/}
            {/*                />*/}
            {/*            }*/}
            {/*            label="Walk-In Purchase (hide Notice of Right to Cancel)"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{!isPrintMode && (*/}
            {/*    <div style={{ marginBottom: "1rem" }}>*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox*/}
            {/*                    checked={isPhysicalWalkIn}*/}
            {/*                    onChange={(e) => {*/}
            {/*                        const newWalkIn = e.target.checked;*/}
            {/*                        // Update local state optimistically*/}
            {/*                        setIsPhysicalWalkIn(newWalkIn);*/}
            {/*                        // Persist the new value to the DB*/}
            {/*                        updateWalkIn({ variables: { jobConfigurationId: jobConfigurationId!, isWalkIn: newWalkIn } });*/}
            {/*                    }}*/}
            {/*                    color="primary"*/}
            {/*                />*/}
            {/*            }*/}
            {/*            label="Walk-In Purchase (hide Notice of Right to Cancel)"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}


            <div
                className={clsx("flex-row", "padding-bottom-sm")}
                style={{
                    alignItems: isPrintMode ? "flex-start" : undefined,
                    height: "19rem",
                }}
            >
            
                <InvoiceContractTermsAndConditions
                    renderExpanded={isPrintMode}
                    onReachedBottom={handleReachedBottomOfTnC}
                />

                <div className="flex-column fill-height">
                    <div className="solid-border">
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <Typography style={{ paddingLeft: ".25rem" }}>
                                <u>Notes:</u>
                            </Typography>
                            <Typography
                                hidden={
                                    depositData.depositPayments.length === 0 ||
                                    totalDeposit > depositAmountErrorThreshold
                                }
                                style={{ paddingLeft: ".25rem", color: "red", fontSize: ".75rem" }}
                            >
                                At least {hasFinancing ? 20 : 50}% deposit required before scheduling
                                installation
                            </Typography>
                        </div>
                        <Input
                            multiline
                            minRows={3}
                            maxRows={3}
                            inputProps={{ maxLength: 140 }}
                            value={jobNotes}
                            onChange={(e) => setJobNotes(e.target.value)}
                            className="fill-width"
                        />
                    </div>

                    <InvoiceTotal
                        isPrintMode={isPrintMode}
                        price={price}
                        financing={financing}
                        depositData={depositData}
                        renderExpanded={isPrintMode}
                        setDepositData={setDepositData}
                        allowRecalculatingDeposit={!isPrintMode}
                        depositAmnt={depositAmnt ?? 0}
                        symbol={symbol ?? "$"}
                    />
                </div>
            </div>

            {/* Customer's Contract Signature */}
            <div
                className="flex-row fill-width"
                style={{ height: "6.25rem", marginBottom: "1rem" }}
            >
                <div className="solid-border flex-grow">
                    <PopoutSignature
                        isPrintMode={isPrintMode}
                        renderSignatureInstead={
                            signedContractDetails?.contractData?.contractSignatureBase64
                        }
                        ref={firstSigRef}
                        open={contractSignatureOpen}
                        setOpen={setContractSignatureOpen}
                        locked={signatureLocked}
                    />
                </div>
                <div className="fill-height solid-border flex-row-center padding-side-sm">
                    <Typography>
                        <b>Approx. Install Date: </b>
                        <br />
                        ASAP
                    </Typography>
                </div>
            </div>

            <div
                hidden={!isPrintMode}
                className="overflow-auto page-break-after avoid-page-break-inside"
            >
                <div className="h-1r" />
                <div className="solid-border padding-side-xsm terms-and-conditions ">
                    <InvoiceAdditionalTermsAndConditions />
                </div>
            </div>

            {/* 
        4) Conditionally show Notice of Right to Cancel
           Hide it if walk-in is checked
      */}
            {!isPhysicalWalkIn && (isPrintMode || showRightToCancel) && (
                <>
                    <InvoiceDocumentHeader
                        documentTitle="Notice of Right To Cancel"
                        sahOrQuoteNumber={sahNumber}
                        {...customer!}
                        salesperson1={data?.jobConfigurationHeader.assignedSalespeople?.[0]}
                        salesperson2={data?.jobConfigurationHeader.assignedSalespeople?.[1]}
                        onEdit={() => setEditCustomerOpen(true)}
                        renderExpanded={isPrintMode}
                    />
                    <div className="fill-width flex-column-center page-break-after">
                        <InvoiceNoticeOfRightToCancelText />
                        <div
                            className="flex-row fill-width margin-bottom-sm"
                            style={{ height: "6.25rem" }}
                        >
                            <div className="solid-border flex-grow">
                                <PopoutSignature
                                    isPrintMode={isPrintMode}
                                    ref={secSigRef}
                                    renderSignatureInstead={
                                        signedContractDetails?.contractData
                                            ?.noticeOfRightSignatureBase64
                                    }
                                    open={cancelSignatureOpen}
                                    setOpen={handleCloseCancelSignature}
                                    locked={false}
                                />
                            </div>
                            <div className="fill-height solid-border flex-row-center padding-side-sm">
                                <Typography>
                                    <b>Signature Date: </b>
                                    <br />
                                    {formatSimpleDate(new Date())}
                                </Typography>
                            </div>
                        </div>
                        {(isPrintMode || showNoticeOfCancel) && (
                            <InvoiceNoticeOfCancellationText endDate={endDate} />
                        )}
                    </div>
                </>
            )}

            {!isPrintMode && (
                <div
                    ref={endRef}
                    className="flex-row fill-width flex-space-between"
                    style={{ paddingTop: ".5rem" }}
                >
                    <Button variant="contained" color="secondary" onClick={goToCart}>
                        To Cart
                    </Button>

                    {/* 
                        Disable the button when canProcess is false. 
                        
                      */}
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!canProcess}
                        style={{ filter: canProcess ? "none" : "blur(2px)" }}
                        onClick={finalizeContract}
                    >
                        Process
                    </Button>
                </div>
            )}

            {/* Loading overlay if contract is submitting */}
            <div
                hidden={!submittingContract}
                style={{
                    backgroundColor: "#cfcfcfb6",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <Loading altText="Finalizing Contract" />
            </div>

            {successDialogOpen &&
                (contractSubmissionData?.addContractToJobWithDeposit ?? -1) > 0 && (
                    <ContractSuccessDialog
                        contractId={contractSubmissionData!.addContractToJobWithDeposit!}
                        customerName={formatNameStringFirstLast(customer)}
                        customerEmail={customer?.email ?? ""}
                    />
                )}

            {editCustomerOpen && (
                <UpdateCustomerDetailDialog
                    open={editCustomerOpen}
                    defaultCustomer={customer!}
                    onCancel={() => setEditCustomerOpen(false)}
                    onSubmit={(newCustomer: Customer) => {
                        updateCustomerDetails({
                            variables: { updatedCustomer: prepareCustomer(newCustomer) },
                        }).then(() => {
                            setEditCustomerOpen(false);
                        });
                    }}
                    canChangePrimary={true}
                />
            )}
        </div>
    );
}
