import { Button } from "@material-ui/core";
import NavbarPage from "Components/Page";
import { FinancingOption, useGetFinancingTableDataQuery } from "generated/graphql";
import { isNotNullOrUndefined } from "Globals/GenericValidators";
import { useMemo, useState } from "react";
import FinancingRow from "./FinancingRow";
import NewFinancingOptionDialog from "./NewFinancingOptionDialog";

interface FinancingType {
    id: number;
    name: string;
}

export interface FinancingOptionRenderRow extends Omit<FinancingOption, "typeId"> {
    type: FinancingType;
}

export type FinancingTypeMap = {[key: number]: string};

// TODO: there's some prop drilling going on with the new option dialog, reimplement with redux
// TODO: find some way to alert user they have unsaved changes if they try to navigate away...
// the useBeforeunload hook works when navigating using the browser address bar, but doesn't work for going back or for using the navigation buttons on the site
// TODO: iron out how to prevent user from leaving the page when there are unsaved changes
// TODO: this component's children desperately need to be refactored
// TODO: button placement could be cleaned up bit
// FIXME: Left side of table is cut off on smaller screens


export default function FinancingPage() {
    const { data: tableData } = useGetFinancingTableDataQuery();
    const rows = useMemo(() =>tableData?.financingTableOptions ?? [], [tableData?.financingTableOptions]);
    const financingTypes = tableData?.financingTypes ?? [];
    
    const existingTranCodes = useMemo(() => {
        let codes: string[] = [];
        rows.forEach(row => {
            codes.push(row.id); // this is the base tran code
            if (row.activePromo) {
                codes.push(row.activePromo.tranCode);
            }
        });

        // only unique entries
        return Array.from(new Set(codes));
    }, [rows]);

    const financingTypeMap = useMemo(() => {
        const types = tableData?.financingTypes ?? [];
        // map ID to its name
        let tMap: FinancingTypeMap = {};
        types.forEach(t => tMap[t.id] = t.name);
        return tMap;
    }, [tableData?.financingTypes]);

    const renderPromoColumns = rows.some(row => isNotNullOrUndefined(row.activePromo));

    const [newRowEditorOpen, setNewRowEditorOpen] = useState(false);
    
    // TODO: iron out how this and <Prompt .../> are going to work
    // useBeforeunload(() => "")
  
    return (
        // <NavbarPage title="Financing" overflowX="auto" style={{width: "fit-content", paddingLeft: "2.5rem", paddingRight: "2.5rem"}}>
        <NavbarPage centerHorizontally title="Financing" style={{paddingLeft: "2.5rem", paddingRight: "2.5rem", overflowX: "auto"}}>
            {/* <Prompt when={true} message="test" /> */}
            <div className="table-fixed-new flex-column align-items-center" style={{height: "100%", width: "fit-content"}} >
                <table>
                    <thead>
                        <tr style={{ backgroundColor: "lightgrey", height: "3rem" }}>
                            <td style={{paddingLeft: ".25rem"}}>Actions</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Term (months)</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Total Amount ($)</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Type</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Fee</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Tran Code</td>
                            <td align="left" className="whitespace-no-wrap padding-right-sm">Slot</td>
                            <td className="shrink" style={{borderLeft: "2px solid black", paddingLeft: "0.5rem", paddingRight: ".25rem"}}>{!renderPromoColumns && "Promo"}</td>  {/* Placeholder for icon buttons */}
                            {renderPromoColumns && (
                                <>
                                    <td align="left" className="whitespace-no-wrap padding-right-sm">Promo Dates</td>
                                    <td align="left" className="whitespace-no-wrap padding-right-sm">Total Amount ($)</td>
                                    <td align="left" className="whitespace-no-wrap padding-right-sm">Fee</td>
                                    <td align="left" className="whitespace-no-wrap padding-right-sm">Tran Code</td>
                                    <td align="left" className="whitespace-no-wrap padding-right-sm" >Slot</td>
                                </>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {rows.map(opt => (
                            <FinancingRow
                                key={`fin-row-${opt.id}`} row={opt}
                                typeMap={financingTypeMap} existingTranCodes={existingTranCodes}
                            />))
                        }
                    </tbody>
                </table>
                <Button variant="contained" className="margin-vertical-sm" onClick={() => setNewRowEditorOpen(true)}>Add Row</Button>
                {newRowEditorOpen && (
                    <NewFinancingOptionDialog setOpen={setNewRowEditorOpen} existingTranCodes={existingTranCodes} financingTypes={financingTypes}/>
                )}
            </div>
        </NavbarPage>
    )
}