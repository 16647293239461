import PickNPlaceTable, {
    ASSIGNED_APPOINTMENT_TABLE,
    UNSCHEDULED_APPOINTMENT_TABLE
} from "Components/PickNPlace/PickNPlaceTable";
import {
    BlockedMarketTimeSlot, MarketTimeSlot,
    namedOperations,
    SahAppointment,
    Salesperson,
    useAddWorkerBlockedTimeSlotMutation,
    useCancelSahAppointmentMutation,
    useCheckIntoSahAppointmentMutation,
    useCheckOutOfSahAppointmentMutation,
    useDeleteWorkerBlockedTimeSlotMutation,
    useGetChatForJobLazyQuery, useGetSahAppointmentsForDateQuery,
    useReleaseSahAppointmentMutation, useUpdateSahAppointmentMutation
} from "generated/graphql";
import {
    dateTimeStrToDay,
    dateToYmd, dayStrictlyBefore, timeSpanStrToHHMM,
    timeSpanStrToHHMM12HR,
    timeSpanStrToMinutes,
    timeSpanStrToTime,
    todayAsDay
} from "Globals/DateAndTimeHelpers";
import { isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import { useCallback, useMemo, useState } from "react";
import { Item, ItemParams, Menu, PredicateParams, useContextMenu } from "react-contexify";
import { openChat } from "Redux/chatDrawerReducer";
import { useAppDispatch } from "Redux/hooks";
import { CellAndLocation } from "Redux/pickNPlaceReducer";
import { COMMAND_CENTER_MENU_ID } from "../CommandCenterPage";
import {
    ACCEPTED_BY_SALESPERSON_STATUS_ID,
    ASSIGNED_STATUS_ID,
    CHECKED_IN_STATUS_ID,
    CHECKED_OUT_STATUS_ID,
    CUSTOMER_NOT_HOME_STATUS_ID,
    RELEASED_TO_SALESPERSON_STATUS_ID,
    WAITING_FOR_CUSTOMER_STATUS_ID,
    WRITE_UP_STATUS_ID
} from "./AppointmentStatus";
import CommandCenterAppointmentCell, {
    CommandCenterCellData,
    getRatingColor
} from "./CommandCenterAppointmentCell";
import RescheduleAppointmentMenu from "./RescheduleAppointmentMenu";

import clsx from "clsx";
import { originalContractPath, quoteDrawingPath } from "Globals/PathStrings";
import { formatNameStringLastFirst } from "Globals/StringFormatting";
import EditDialogMenu from "./EditDialogMenu";

const GRAY_SHADING = "#99999999";
const COLUMN_WIDTH = "10em";
const D_GRAY_SHADING = "#44444499";

interface CommandCenterBodyProps {
    thisCcWorkerId: number;
    generalCcWorkerId: number;
    date: string;
    marketId: number;
    use24HrFormat: boolean;
    timeSlots: MarketTimeSlot[];
    salespeople: Salesperson[];
    highlightAppointmentId: number | null;
}

export default function CommandCenterBody({
    thisCcWorkerId,
    generalCcWorkerId,
    date,
    marketId,
    use24HrFormat,
    timeSlots,
    salespeople,
    highlightAppointmentId,
}: CommandCenterBodyProps) {
    const totalDisplayColumns = timeSlots.length + 1;
    const screenWidth = window.innerWidth;
    const pixelsPerRem = +window.getComputedStyle(document.body).fontSize.split("px")[0];
    const widthInRems = screenWidth / pixelsPerRem;

    const tableTargetFontSize = `min(1rem, ${widthInRems}rem / (${totalDisplayColumns + 0.5} * ${
        COLUMN_WIDTH.split("em")[0]
    }))`;

    const [editDialog, setEditDialog] = useState<SahAppointment | null>(null);

    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const currentTimeBlockIndex =
        timeSlots.findIndex((ts) => currentMinutes < timeSpanStrToMinutes(ts.endTime)) - 1;

    const isToday = dateToYmd(now) === date;

    const { data, startPolling, stopPolling } = useGetSahAppointmentsForDateQuery({
        variables: { date, marketId },
        fetchPolicy: "cache-and-network",
        pollInterval: 2000,
    });

    const [updateAppointment] = useUpdateSahAppointmentMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForDate],
    });

    const startTimeArray = useMemo(() => timeSlots.map((ts) => ts.startTime), [timeSlots]);
    const timeSlotArray = useMemo(() => timeSlots.map((ts) => ts.id), [timeSlots]);
    const salespersonArray = useMemo(() => salespeople.map((sp) => sp.id), [salespeople]);

    //#region Cell Rendering
    const columns = useMemo(() => {
        const formatter = use24HrFormat ? timeSpanStrToHHMM : timeSpanStrToHHMM12HR;
        return timeSlots.map((mts) => `${formatter(mts.startTime)} - ${formatter(mts.endTime)}`);
    }, [timeSlots, use24HrFormat]);

    const sahAppointments = useMemo(
        () => [...(data?.sAHAppointmentsForDate ?? [])],
        [data?.sAHAppointmentsForDate]
    );

    const rows = useMemo(
        () =>
            salespeople.map((sp) => {
                let backgroundColor: string | undefined;

                // Only show colors on current day
                if (!isToday) backgroundColor = "var(--flat-gray-4)";
                // The tables background color is normally blue.
                // When checked in, show no color
                else if (sp.isCheckedIn) backgroundColor = "var(--flat-faded-blue)";
                else {
                    // The salesperson isn't checked in
                    if (
                        sahAppointments.some(
                            (appt) => appt.currentSalesperson?.salespersonId === sp.id
                        )
                    ) {
                        backgroundColor = "var(--flat-faded-red)";
                    } else {
                        backgroundColor = "var(--flat-gray-4)";
                    }
                }

                var salespersonRating = ((sp.id * 19027 * 7.1) % 6) + 2;

                return (
                    <div
                        key={`salesperson-row-${sp.id}`}
                        style={{
                            backgroundColor: backgroundColor,
                            justifyContent: "center",
                        }}
                        className="flex-column flex-centered fill-width fill-height flat-has-customer-rating-indicator"
                    >
                        <div
                            className="flat-font-sm"
                            style={{ fontSize: "12pt" }}
                        >
                            {formatNameStringLastFirst(sp)}
                        </div>
                        <div
                            className={clsx(
                                getRatingColor(salespersonRating),
                                "flat-font-sm flat-customer-rating-indicator"
                            )}
                            style={{ color: "white" }}
                        >
                            {salespersonRating.toFixed(1)}
                        </div>
                    </div>
                );
            }),
        [salespeople, sahAppointments, isToday]
    );

    const tableDataRows = useMemo(() => {
        return [...sahAppointments, ...(data?.allBlockedTimesSlotsOnDay ?? [])];
    }, [data, sahAppointments]);

    const shadeScheduledCells = useCallback(
        (
            selectedCell: CellAndLocation<any>,
            currentCell: CellAndLocation<CommandCenterCellData>
        ) => {
            if (isToday && currentCell.location.column <= currentTimeBlockIndex)
                return D_GRAY_SHADING;
            else if (currentCell.location.column !== selectedCell.location.column)
                return GRAY_SHADING;
        },
        [currentTimeBlockIndex, isToday]
    );

    const canPlaceInScheduled = useCallback(
        (
            selectedCell: CellAndLocation<any>,
            currentCell: CellAndLocation<CommandCenterCellData | undefined>
        ) => {
            // warn if the salesperson doesn't service the zip code the appointment is for
            if (selectedCell.cell.data?.__typename === "SAHAppointment") {
                const cellAsAppt = (selectedCell.cell.data!) as SahAppointment;
                const apptZip = (cellAsAppt).primaryZipCode;
                const spRow = currentCell.location.row;
                const spZips = salespeople[spRow].serviceZips;
                if (!spZips.includes(apptZip) && !(window.confirm("This sales agent doesn't service this area, are you sure you wish to assign them to this appointment?"))) {
                    return false;
                }

                // prevent assignment on a previous date
                if (dayStrictlyBefore(dateTimeStrToDay(cellAsAppt.date), todayAsDay())) {
                    alert("You are trying to assign this appointment to a date in the past. Please reschedule the appointment for today, or a date in the future.");
                    return false;
                }

                if (cellAsAppt.appointmentStatusId === RELEASED_TO_SALESPERSON_STATUS_ID) {
                    alert("You have already released this appointment to the salesperson. To reassign or reschedule, first unassign the salesperson.")
                    return false;
                }
            }
            /**
             * Temporarily allow to scheduled appointments to be asigned to the past time 
             * due to one user who had 4 or 5 appointments after the normal working hours
             * This is temporary up and until the stakeholders change this requirement
             * Normally we shouldnt allow to assign an appointment to the past time
             * Return false would make assigning of past times to be disallowed
             */

            if (isToday && currentCell.location.column <= currentTimeBlockIndex) return true;
            else if (currentCell.location.column !== selectedCell.location.column) {
                return window.confirm(
                    "The appointment time will be changed. Ensure this is confirmed with the customer."
                );
            } else return true;
        },
        [currentTimeBlockIndex, isToday, salespeople]
    );

    const canPickCellInScheduled = useCallback((cell: CellAndLocation<CommandCenterCellData>) => {
        const cellAsSAH = cell.cell.data as SahAppointment;
        return (
            cellAsSAH.__typename === "SAHAppointment" &&
            isNullOrUndefined(cellAsSAH.currentSalesperson?.checkInTime)
        );
    }, []);

    const assignDataForUnassignedTable = useCallback(
        (data: CommandCenterCellData) => {
            const appointment = data as SahAppointment;

            if (appointment.__typename !== "SAHAppointment") return undefined;
            else if (
                !isNullOrUndefined(appointment?.currentSalesperson) &&
                !appointment.isCancelled
            )
                return undefined;
            else
                return {
                    colIndex: startTimeArray.indexOf(appointment?.marketTimeSlot.startTime),
                };
        },
        [startTimeArray]
    );

    const shadeUnassignedCells = useCallback(
        (
            selectedCell: CellAndLocation<any>,
            currentCell: CellAndLocation<CommandCenterCellData>
        ) => {
            if (isToday && selectedCell.location.table === ASSIGNED_APPOINTMENT_TABLE) {
                return "var(--flat-gray-4)";
            } else if (currentCell.location.column <= currentTimeBlockIndex) return D_GRAY_SHADING;
        },
        [currentTimeBlockIndex, isToday]
    );

    const shouldHighlightCell = useCallback(
        (cell: CellAndLocation<any>) => {
            if (highlightAppointmentId && isNotNullOrUndefined(cell.cell?.data)) {
                const cellAsSAH = cell.cell.data as SahAppointment;
                return (
                    cellAsSAH.__typename === "SAHAppointment" &&
                    cellAsSAH.id === highlightAppointmentId
                );
            } else return false;
        },
        [highlightAppointmentId]
    );

    const canPlaceInUnassigned = useCallback(
        (
            selectedCell: CellAndLocation<any>,
            currentCell: CellAndLocation<CommandCenterCellData | undefined>
        ) => {
            if (isToday && currentCell.location.column <= currentTimeBlockIndex) {
                return false;
            } else if (currentCell.location.column !== selectedCell.location.column) {
                return window.confirm(
                    "The appointment time will be changed. Ensure this is confirmed with the customer."
                );
            } else if (selectedCell.location.table === ASSIGNED_APPOINTMENT_TABLE) {
                //window.alert("Right click on appointment to unassign");
                return true;
            } else return true;
        },
        [currentTimeBlockIndex, isToday]
    );

    const canPickCellInUnassigned = useCallback((cell: CellAndLocation<CommandCenterCellData>) => {
        const cellAsSAH = cell.cell.data as SahAppointment;
        return cellAsSAH.__typename === "SAHAppointment" && !cellAsSAH.isCancelled;
    }, []);

    const assignDataForAssignedTable = useCallback(
        (data: CommandCenterCellData) => {
            const appointment = data as SahAppointment;
            const blocked = data as BlockedMarketTimeSlot;

            if (appointment.__typename !== "SAHAppointment") {
                if (blocked.__typename === "BlockedMarketTimeSlot") {
                    return {
                        colIndex: timeSlotArray.indexOf(blocked.marketTimeSlotId),
                        rowIndex: salespersonArray.indexOf(blocked.salespersonId),
                    };
                } else return undefined;
            } else if (
                isNullOrUndefined(appointment?.currentSalesperson) ||
                appointment.isCancelled
            )
                return undefined;
            else
                return {
                    colIndex: startTimeArray.indexOf(appointment?.marketTimeSlot.startTime),
                    rowIndex: salespersonArray.indexOf(
                        appointment?.currentSalesperson!.salespersonId
                    ),
                };
        },
        [startTimeArray, salespersonArray, timeSlotArray]
    );

    const startPollingQuery = useCallback(() => startPolling(2000), [startPolling]);

    const moveCell = useCallback(
        (
            selectedCell: CellAndLocation<any>,
            currentCell: CellAndLocation<CommandCenterCellData>
        ) => {
            const targetTimeBlockIndex = currentCell.location.column;
            const targetSalespersonIndex = currentCell.location.row;
            const targetTable = currentCell.location.table;

            const noSPSelected =
                targetSalespersonIndex === -1 || targetTable === UNSCHEDULED_APPOINTMENT_TABLE;

            const salespersonId = noSPSelected ? null : salespeople[targetSalespersonIndex].id;
            const timeblock = timeSlots[targetTimeBlockIndex];

            stopPolling();

            updateAppointment({
                variables: {
                    sahAppointmentId: (currentCell.cell.data as SahAppointment).id,
                    salespersonId,
                    marketTimeSlotIds: [timeblock.id],
                    date: null,
                },
            }).finally(startPollingQuery);
        },
        [updateAppointment, salespeople, timeSlots, stopPolling, startPollingQuery]
    );
    //#endregion

    const getRightClickProps: (
        cell: CellAndLocation<CommandCenterCellData | undefined>
    ) => RightClickedProps = useCallback(
        (cell) => {
            return {
                data: cell.cell?.data,
                salesperson:
                    cell.location.table === UNSCHEDULED_APPOINTMENT_TABLE
                        ? undefined
                        : salespeople[cell.location.row],
                timeSlot: timeSlots[cell.location.column],
            };
        },
        [salespeople, timeSlots]
    );

    const { show } = useContextMenu({ id: COMMAND_CENTER_MENU_ID });

    return (
        <>
            <div
                style={{
                    maxHeight: "calc(40px + 15.35em)",
                    overflow: "auto",
                    fontSize: tableTargetFontSize,
                }}
            >
                <PickNPlaceTable
                    tableName={UNSCHEDULED_APPOINTMENT_TABLE}
                    columns={columns}
                    data={tableDataRows}
                    columnWidth={COLUMN_WIDTH}
                    assignData={assignDataForUnassignedTable}
                    RenderCell={CommandCenterAppointmentCell}
                    shadeCellWhenOtherPicked={shadeUnassignedCells}
                    canPlaceAtCell={canPlaceInUnassigned}
                    canPickCell={canPickCellInUnassigned}
                    onCellPlaced={moveCell}
                    onRightClicked={(e, cell) => show(e, { props: getRightClickProps(cell) })}
                    shouldHighlightCell={shouldHighlightCell}
                    cellBackgroundColor="var(--flat-gray-4)"
                />
            </div>
            <div style={{ fontSize: tableTargetFontSize }}>
                <PickNPlaceTable
                    tableName={ASSIGNED_APPOINTMENT_TABLE}
                    columns={columns}
                    rows={rows}
                    data={tableDataRows}
                    columnWidth={COLUMN_WIDTH}
                    assignData={assignDataForAssignedTable}
                    RenderCell={CommandCenterAppointmentCell}
                    shadeCellWhenOtherPicked={shadeScheduledCells}
                    canPlaceAtCell={canPlaceInScheduled}
                    canPickCell={canPickCellInScheduled}
                    onCellPlaced={moveCell}
                    onRightClicked={(e, cell) => show(e, { props: getRightClickProps(cell) })}
                    shouldHighlightCell={shouldHighlightCell}
                    hideHeaderRow={true}
                />
            </div>
            
            <CommandCenterMenu
                thisCcWorkerId={thisCcWorkerId}
                generalCcWorkerId={generalCcWorkerId}
                date={date}
                showCustomer={(appointment) => setEditDialog(appointment)}
                startPolling={startPollingQuery}
                stopPolling={stopPolling}
            />

            {editDialog !== null && (
                <EditDialogMenu
                    appointment={editDialog}
                    onClose={() => setEditDialog(null)}
                />
            )}
        </>
    );
}

interface RightClickedProps {
    data?: CommandCenterCellData;
    salesperson?: Salesperson;
    timeSlot: MarketTimeSlot;
}

interface CommandCenterMenuProps {
    date: string;
    showCustomer: (appointment: SahAppointment) => void;
    thisCcWorkerId: number;
    generalCcWorkerId: number;
    startPolling: () => void;
    stopPolling: () => void;
}

function CommandCenterMenu({
    date,
    showCustomer,
    thisCcWorkerId,
    generalCcWorkerId,
    startPolling,
    stopPolling,
}: CommandCenterMenuProps) {
    const dispatch = useAppDispatch();

    const [releaseAppointment] = useReleaseSahAppointmentMutation();
    const [checkIntoAppointment] = useCheckIntoSahAppointmentMutation();
    const [checkOutOfAppointment] = useCheckOutOfSahAppointmentMutation();
    const [cancelAppointment] = useCancelSahAppointmentMutation();

    const [rescheduleAppointment, setRescheduleAppointment] = useState<SahAppointment>();

    const [updateAppointment] = useUpdateSahAppointmentMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForDate],
    });

    const [deleteTimeBlock] = useDeleteWorkerBlockedTimeSlotMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForDate],
    });

    const [addTimeBlock] = useAddWorkerBlockedTimeSlotMutation({
        refetchQueries: [namedOperations.Query.GetSahAppointmentsForDate],
    });

    //#region Reschedule
    function onRescheduleClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        setRescheduleAppointment(appointment);
    }

    function isRescheduledHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;

        return (
            isNullOrUndefined(props?.data) ||
            props?.data?.__typename === "BlockedMarketTimeSlot" ||
            // Salesperson has checked in and they aren't waiting for the customer to arrive
            (!isNullOrUndefined(appointment.currentSalesperson?.checkInTime) &&
                appointment.appointmentStatusId !== CUSTOMER_NOT_HOME_STATUS_ID &&
                appointment.appointmentStatusId !== WAITING_FOR_CUSTOMER_STATUS_ID)
        );
    }
    //#endregion

    //#region Delete Block
    function onDeleteBlockClick({ props }: ItemParams<RightClickedProps, any>) {
        const blockedTimeSlot = props?.data as BlockedMarketTimeSlot;
        stopPolling();
        deleteTimeBlock({
            variables: {
                workerBlockedTimeSlotId: blockedTimeSlot.workerBlockedTimeSlotIdIfDeletable!,
            },
        }).finally(startPolling);
    }

    function isDeleteBlockedHidden({ props }: PredicateParams<RightClickedProps, any>) {
        return (
            isNullOrUndefined(props) ||
            props?.data?.__typename !== "BlockedMarketTimeSlot" ||
            isNullOrUndefined(props.data.workerBlockedTimeSlotIdIfDeletable)
        );
    }
    //#endregion

    //#region Add Block
    function onAddBlockClick({ props }: ItemParams<RightClickedProps, any>) {
        var { salesperson, timeSlot } = props!;

        const start = timeSpanStrToTime(timeSlot.startTime);
        const end = timeSpanStrToTime(timeSlot.endTime);

        stopPolling();
        addTimeBlock({
            variables: {
                workerId: salesperson!.workerId,
                date: date,
                timeSlot: {
                    startHour: start.hour,
                    startMinute: start.minute,
                    endHour: end.hour,
                    endMinute: end.minute,
                },
                isBlockedByWorker: false,
            },
        }).finally(startPolling);
    }

    const [getChatForJob] = useGetChatForJobLazyQuery();

    function isAddBlockedHidden({ props }: PredicateParams<RightClickedProps, any>) {
        return !isNullOrUndefined(props?.data) || isNullOrUndefined(props?.salesperson);
    }
    //#endregion

    //#region Release
    function onReleaseClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        stopPolling();
        releaseAppointment({ variables: { sahAppointmentId: appointment!.id } }).finally(
            startPolling
        );
    }

    function isReleaseHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return appointment?.appointmentStatusId !== ASSIGNED_STATUS_ID || appointment?.isCancelled;
    }
    //#endregion

    //#region Unassign
    function onUnassignClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;

        if (!isNullOrUndefined(appointment?.id)) {
            stopPolling();
            updateAppointment({
                variables: { sahAppointmentId: appointment!.id, salespersonId: undefined },
            }).finally(startPolling);
        }
    }

    function isUnassignHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        if (appointment?.__typename === "SAHAppointment")
            return (
                isNullOrUndefined(appointment?.currentSalesperson) ||
                appointment?.isCancelled ||
                (appointment?.appointmentStatusId === CHECKED_OUT_STATUS_ID &&
                    isNotNullOrUndefined(appointment.contractTotalPrice))
            );
        else return true;
    }

    //#endregion

    //#region Check In
    function onCheckIntoClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        stopPolling();
        checkIntoAppointment({ variables: { sahAppointmentId: appointment!.id } }).finally(
            startPolling
        );
    }

    function isCheckIntoHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return (
            appointment?.appointmentStatusId !== ACCEPTED_BY_SALESPERSON_STATUS_ID ||
            appointment?.isCancelled
        );
    }
    //#endregion

    //#region Check Out
    function onCheckOutOfClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        stopPolling();
        checkOutOfAppointment({ variables: { sahAppointmentId: appointment!.id } }).finally(
            startPolling
        );
    }

    function isCheckOutOfHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return (
            appointment?.appointmentStatusId !== CHECKED_IN_STATUS_ID || appointment?.isCancelled
        );
    }
    //#endregion

    //#region See Customer Details
    function onSeeCustomerDetailsClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        showCustomer(appointment);
    }

    function isSeeCustomerDetailsHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return appointment?.__typename !== "SAHAppointment";
    }
    //#endregion

    //#region View Contract
    // TODO: once the PDFs are uploaded to S3, we should instead just open the PDF in a dialog with S3PdfViewerDialog
    function onViewContractClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        window.open(`${originalContractPath}/${appointment.contractId}`)
    }

    function isViewContractHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return isNullOrUndefined(appointment.contractId);
    }
    //#endregion

    function onViewQuoteDrawingClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        window.open(`${quoteDrawingPath}/${appointment.quoteId}`);
    }

    function isViewQuoteDrawingHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        // don't show quote if there's a contract for the job
        return isNullOrUndefined(appointment.quoteId) && isNotNullOrUndefined(appointment.contractId);
    }

    //#region Open Chat
    function onOpenChatClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        const jobId = appointment.jobId;
        getChatForJob({ variables: { jobId: jobId } }).then((data) => {
            const thisChat = data.data?.chatForJob;
            if (thisChat) {
                // add the chat to the chat drawer (in an open state)
                dispatch(openChat(thisChat));
            }
        });
    }

    function isOpenChatHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        switch (appointment?.appointmentStatusId) {
            case CHECKED_IN_STATUS_ID:
            case CUSTOMER_NOT_HOME_STATUS_ID:
            case WAITING_FOR_CUSTOMER_STATUS_ID:
            case WRITE_UP_STATUS_ID:
                // QUESTION: should general CC worker be able to view all chats?
                // general CC worker should be able to view all chats
                if (thisCcWorkerId === generalCcWorkerId) return false;

                const handlingCommandCenterWorkerId = appointment.handlingCommandCenterWorkerId;
                if (handlingCommandCenterWorkerId) {
                    // hide if this worker hasn't claimed
                    return handlingCommandCenterWorkerId !== thisCcWorkerId;
                }

                return false; // no CC worker has claimed, so should be able to open chat
            default:
                return true;
        }
    }
    //#endregion

    //#region Cancel
    function onCancelClick({ props }: ItemParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        stopPolling();
        cancelAppointment({ variables: { sahAppointmentId: appointment!.id } }).finally(
            startPolling
        );
    }

    function isCancelHidden({ props }: PredicateParams<RightClickedProps, any>) {
        const appointment = props?.data as SahAppointment;
        return (
            isNullOrUndefined(appointment) ||
            // Salesperson has checked in and they aren't waiting for the customer to arrive
            (!isNullOrUndefined(appointment.currentSalesperson?.checkInTime) &&
                appointment.appointmentStatusId !== CUSTOMER_NOT_HOME_STATUS_ID &&
                appointment.appointmentStatusId !== WAITING_FOR_CUSTOMER_STATUS_ID)
        );
    }
    //#endregion

    return (
        <>
            {rescheduleAppointment !== undefined && (
                <RescheduleAppointmentMenu
                    currentAppointment={rescheduleAppointment!}
                    onClose={() => setRescheduleAppointment(undefined)}
                />
            )}
            <Menu id={COMMAND_CENTER_MENU_ID}>
                <Item
                    id="reschedule"
                    hidden={isRescheduledHidden}
                    onClick={onRescheduleClick}
                >
                    Reschedule
                </Item>
                <Item
                    id="delete-block"
                    hidden={isDeleteBlockedHidden}
                    onClick={onDeleteBlockClick}
                >
                    Delete Block
                </Item>
                <Item
                    id="add-block"
                    hidden={isAddBlockedHidden}
                    onClick={onAddBlockClick}
                >
                    Block Time Slot
                </Item>
                <Item
                    id="release"
                    hidden={isReleaseHidden}
                    onClick={onReleaseClick}
                >
                    Release
                </Item>
                <Item
                    id="unschedule"
                    onClick={onUnassignClick}
                    hidden={isUnassignHidden}
                >
                    Unassign
                </Item>
                <Item
                    id="check-in"
                    hidden={isCheckIntoHidden}
                    onClick={onCheckIntoClick}
                >
                    Check In
                </Item>
                <Item
                    id="check-out"
                    hidden={isCheckOutOfHidden}
                    onClick={onCheckOutOfClick}
                >
                    Check Out
                </Item>
                <Item
                    id="customer-details"
                    hidden={isSeeCustomerDetailsHidden}
                    onClick={onSeeCustomerDetailsClick}
                >
                    See Customer Details
                </Item>
                <Item
                    id="view-contract"
                    hidden={isViewContractHidden}
                    onClick={onViewContractClick}
                >
                    View Contract
                </Item>
                <Item
                    id="view-quote-drawing"
                    hidden={isViewQuoteDrawingHidden}
                    onClick={onViewQuoteDrawingClick}
                >
                    View Drawing
                </Item>
                <Item
                    id="open-job-chat"
                    hidden={isOpenChatHidden}
                    onClick={onOpenChatClick}
                >
                    Open Chat
                </Item>
                <Item
                    id="cancel-job"
                    hidden={isCancelHidden}
                    onClick={onCancelClick}
                >
                    Cancel
                </Item>
            </Menu>
        </>
    );
}