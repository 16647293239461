import {
    Button,
    Dialog,
    IconButton,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import Loading from "Components/Loading";
import { GetJobInvoiceDataQuery } from "generated/graphql";
import { allTrue, isNotNullOrUndefined, isNumber } from "Globals/GenericValidators";
import { useEffect, useState } from "react";
import "./InvoiceStyling.css";

export type PaymentMethodTypes = "cc" | "check" | "cash";

export interface DepositPaymentMethod {
    amount: number;
    method: PaymentMethodTypes;
}

export interface InvoicePaymentData {
    depositPayments: DepositPaymentMethod[];
    financingAccountNumber: string;
}

interface InvoiceTotalProps
    extends Pick<GetJobInvoiceDataQuery["jobConfiguration"], "price" | "financing"> {
    isPrintMode: boolean;
    depositData: InvoicePaymentData;
    setDepositData: (newValue: InvoicePaymentData) => void;
    allowRecalculatingDeposit: boolean;
    renderExpanded: boolean;
    depositAmnt: number;
    symbol: string;
}

export function InvoiceTotal({
    isPrintMode,
    price,
    financing,
    depositData,
    setDepositData,
    renderExpanded,
    depositAmnt,
    symbol
}: InvoiceTotalProps) {
    const [open, setOpen] = useState(false);

    const hasFinancing = isNotNullOrUndefined(financing?.financingOption);
    

    
    const total = price?.total??0;

    
          

    const depositAmount = findDeposit(total, depositAmnt, symbol);
    const depositString = findDepositString(total, depositAmnt, symbol);


    const targetDeposit = hasFinancing
        ? depositAmount // Default financing down payment
        : 0.5 * (price?.total ?? 0);

    const targetPercentage = hasFinancing ? undefined : 50;
    const totalDeposit = calculateInvoiceTotal(depositData.depositPayments);

    const { financingAccountNumber } = depositData;

    function updateDepositField({ ...fields }: Partial<InvoicePaymentData>) {
        setDepositData({ ...depositData, ...fields });
    }

    const unfilteredCod = (total ?? 0) - totalDeposit;
    //Filters the COD so the number is never displayed if less than 0
    const cod = +(unfilteredCod ?? -1).toFixed(2) < 0 ? undefined : Math.abs(unfilteredCod ?? 0);

    const displayPayments: { [key: string]: number } = {};
    depositData.depositPayments.forEach((payment) => {
        displayPayments[payment.method] = (displayPayments?.[payment.method] ?? 0) + payment.amount;
    });
    const foundPaymentTypes = Object.keys(displayPayments);

    const [pdfHook, setPdfHook] = useState("");
    // wait 10 seconds to set the hook to allow all queries to successfully load
    useEffect(() => {
        setTimeout(() => setPdfHook("invoice-pdf-hook"), 10000);
    }, []);

    return (
        <>
            {(total !== undefined && isNotNullOrUndefined(financing) && targetDeposit !== undefined) ? (
                <div
                    className="flex-grow"
                    id={pdfHook}
                    style={{ height: "12rem", minWidth: "30rem" }}
                >
                    <table
                        className="fill-width"
                        style={{ border: "1px solid black" }}
                    >
                        <tbody>
                            <tr>
                                <th
                                    className="solid-border gray-bkg"
                                    style={{ width: "6rem", textAlign: "center", height: "4rem" }}
                                >
                                    <Typography className="grid-center-text">
                                        Contract Total
                                    </Typography>
                                </th>
                                <td
                                    colSpan={2}
                                    className="solid-border"
                                >
                                    <Typography className="grid-format-static-money-lg">{`$${total!.toFixed(
                                        2
                                    )}`}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <th
                                    className="solid-border gray-bkg"
                                    style={{ width: "6rem", textAlign: "center", height: "4rem" }}
                                >
                                    <Typography className="grid-center-text">Deposit</Typography>
                                </th>
                                {foundPaymentTypes.length === 0 && (
                                    <td colSpan={2}>
                                        <div
                                            className="flex-row"
                                            style={{ justifyContent: "center" }}
                                        >
                                            {!isPrintMode && (
                                                <Button
                                                    onClick={() => setOpen(true)}
                                                    variant="contained"
                                                >
                                                    Add Deposit
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                )}
                                {foundPaymentTypes.length !== 0 && (
                                    <>
                                        <td className="solid-border">
                                            <Typography className="grid-format-static-money">
                                                {`$${totalDeposit.toFixed(2)}`}

                                                {!isPrintMode && (
                                                    <IconButton
                                                        hidden={renderExpanded}
                                                        onClick={() => setOpen(true)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                            </Typography>
                                        </td>

                                        <td className="solid-border">
                                            <div className="flex-column">
                                                {foundPaymentTypes.map((paymentType) => {
                                                    const payment = displayPayments[paymentType];
                                                    const displayType =
                                                        paymentType === "cc"
                                                            ? "credit card"
                                                            : paymentType;

                                                    const fontSize = Math.min(
                                                        1,
                                                        4 / (foundPaymentTypes.length * 1.65)
                                                    );

                                                    return (
                                                        <Typography
                                                            style={{ fontSize: `${fontSize}rem` }}
                                                            key={paymentType}
                                                        >
                                                            {displayType.toUpperCase()} - $
                                                            {payment.toFixed(2)}
                                                        </Typography>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <th
                                    className="solid-border gray-bkg"
                                    style={{ width: "6rem", textAlign: "center", height: "4rem" }}
                                >
                                    <Typography className="grid-center-text">C.O.D.</Typography>
                                </th>
                                <td
                                    className="solid-border"
                                    style={{ width: "10rem" }}
                                >
                                    <Typography className="grid-format-static-money">{`$${
                                        depositData.depositPayments.length === 0
                                            ? "---.--"
                                            : cod?.toFixed(2)
                                    }`}</Typography>
                                </td>
                                <td
                                    className={clsx("solid-border", { "gray-bkg": !hasFinancing })}
                                    style={{ padding: 0 }}
                                >
                                    {hasFinancing ? (
                                        <div className="fill-width flex-column h-4r">
                                            <div
                                                className="fill-width gray-bkg flex-grow"
                                                style={{ borderBottom: "1px solid black" }}
                                            >
                                                <Typography className="grid-center-text">{`${
                                                    financing!.financingOption!.term
                                                }-month 0% Financing`}</Typography>
                                            </div>
                                            <div className="fill-width flex-grow padding-side-xsm">
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        fontSize: "small",
                                                    }}
                                                >
                                                    Acc#
                                                </div>
                                                <Input
                                                    type="number"
                                                    className="hide-input-button fill-width"
                                                    inputProps={{ style: { textAlign: "right" } }}
                                                    value={financingAccountNumber}
                                                    onChange={(e) =>
                                                        updateDepositField({
                                                            financingAccountNumber:
                                                                e.currentTarget.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <Typography className="grid-center-text">
                                            Cash/Check/Credit Card
                                        </Typography>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {open && (
                        <InvoiceDepositDialog
                            open={open}
                            setOpen={setOpen}
                            targetAmount={targetDeposit!}
                            requiredPercentage={targetPercentage!}
                            depositData={depositData}
                            setDepositData={setDepositData}
                            depositString={depositString}
                            depositAmount={depositAmount}
                            hasFinancing={hasFinancing}
                        />
                    )}
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
}

export function calculateInvoiceTotal(
    payments: { amount: number | string; method: "cc" | "cash" | "check" | "" }[]
): number {
    var total = 0;

    payments.forEach((pay) => {
        var amount = 0;
        if (typeof pay.amount === "number") amount = pay.amount;
        else if (typeof pay.amount === "string" && isNumber(pay.amount)) amount = +pay.amount;

        if (pay.method !== "") {
            total += amount;
        }
    });

    return total;
}
 export function findDepositString(
    total: number,
    depositAmount: number | null | undefined,
    symbol: string
): string {
    if (depositAmount == null) {
        return "$200";
    }
    if (symbol === "$") {
        return `$${depositAmount.toFixed(2)}`;
    } else if (symbol === "%") {

        return `${depositAmount.toFixed(0)}%`;
    }

    return "$200";
}
export function findDeposit(
    total: number,
    depositAmount: number | null | undefined,
    symbol: string
): number {
    // If there's no depositAmount at all, return a default value (e.g. 200).
    if (depositAmount == null) {
        return 200;
    }

    // If the symbol is "$", deposit is just the depositAmount as-is.
    if (symbol === "$") {
        return depositAmount;
    }
    // If the symbol is "%", then deposit is "depositAmount% of total".
    else if (symbol === "%") {
        return (depositAmount / 100) * total;
    }

    // If the symbol doesn't match anything we expect, fall back to a default.
    return 300;
}

interface InvoiceDepositDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    targetAmount: number;
    requiredPercentage?: number; // should be an int
    depositData: InvoicePaymentData;
    setDepositData: (newValue: InvoicePaymentData) => void;
    depositString: string;
    depositAmount: number;
    hasFinancing: boolean
}

function InvoiceDepositDialog({
    open,
    setOpen,
    targetAmount,
    requiredPercentage,
    depositData,
    setDepositData,
    depositString,
    depositAmount,
    hasFinancing
}: InvoiceDepositDialogProps) {
    const roundedTargetAmount = +targetAmount.toFixed(2);

    const defaultPaymentValue: { amount: string; method: PaymentMethodTypes | "" }[] =
        depositData.depositPayments.map((pay) => ({
            amount: pay.amount.toFixed(2),
            method: pay.method,
        }));

    const [payments, setPayments] =
        useState<{ amount: string; method: PaymentMethodTypes | "" }[]>(defaultPaymentValue);

    var totalDeposit = calculateInvoiceTotal(payments);

    const pendingBalance = targetAmount - totalDeposit;
    const pendingBalanceDisplay = pendingBalance > 0 ? `$${pendingBalance.toFixed(2)}` : "$0.00";

    function submitDialog() {
        if (allTrue(payments.map((pay) => isNumber(pay.amount)))) {
            if (!payments.some((pay) => pay.method === "")) {
                setDepositData({
                    ...depositData,
                    depositPayments: payments.map((pay) => ({
                        amount: +pay.amount,
                        method: pay.method as "cc" | "check" | "cash",
                    })),
                });
                setOpen(false);
            } else {
                window.alert("Please select missing payment method");
            }
        } else {
            window.alert("Fix errors on payments");
        }
    }

    function cancelDialog() {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={cancelDialog}
        >
            <div className="w-30r padding-sm">
                <Typography style={{ textAlign: "center" }}>
                    {requiredPercentage !== undefined
                        ? `${requiredPercentage}% of contract total`
                        : ""}{" "}
                    (
                    <Typography style={{ color: "red", display: "inline" }}>
                        {hasFinancing ? depositString : `$${roundedTargetAmount}`}
                    </Typography>
                    ) is required.
                </Typography>
                <table style={{ margin: "2rem auto" }}>
                    <tbody>
                        {payments.map((pay, index) => {
                            function updatePayment(newAmount: string) {
                                const copy = [...payments];
                                copy[index].amount = newAmount;
                                setPayments(copy);
                            }

                            function updateMethod(newMethod: string) {
                                if (newMethod === "remove") {
                                    setPayments(payments.filter((v, i) => i !== index));
                                } else {
                                    const copy = [...payments];
                                    copy[index].method = newMethod as PaymentMethodTypes;
                                    setPayments(copy);
                                }
                            }

                            return (
                                <tr key={`payment-row-${index}`}>
                                    <td />
                                    <td className="w-10r">
                                        <TextField
                                            key={`payment-amount-${index}`}
                                            error={!isNumber(pay.amount)}
                                            autoFocus
                                            InputProps={{ startAdornment: "$" }}
                                            value={pay.amount}
                                            onChange={(e) => updatePayment(e.target.value)}
                                        />
                                    </td>
                                    <td className="w-10r">
                                        <Select
                                            onChange={(e) => updateMethod(e.target.value as string)}
                                            value={pay.method}
                                            fullWidth
                                        >
                                            <MenuItem value={"cc"}>Credit Card</MenuItem>
                                            <MenuItem value={"cash"}>Cash</MenuItem>
                                            <MenuItem value={"check"}>Check</MenuItem>
                                            <MenuItem value={"remove"}>Remove</MenuItem>
                                        </Select>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td />
                            <td />
                            <td className="w-10r">
                                <Select
                                    label="Please Select"
                                    value=""
                                    displayEmpty
                                    onChange={(e) =>
                                        setPayments([
                                            ...payments,
                                            {
                                                amount: (pendingBalance > 0
                                                    ? pendingBalance
                                                    : 0
                                                ).toFixed(2),
                                                method: e.target.value as PaymentMethodTypes,
                                            },
                                        ])
                                    }
                                    fullWidth
                                >
                                    <MenuItem
                                        value=""
                                        hidden
                                    >
                                        Add Payment
                                    </MenuItem>
                                    <MenuItem value={"cc"}>Credit Card</MenuItem>
                                    <MenuItem value={"cash"}>Cash</MenuItem>
                                    <MenuItem value={"check"}>Check</MenuItem>
                                </Select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}
                    hidden={payments.length === 0}
                >
                    <Button
                        variant="contained"
                        onClick={submitDialog}
                    >
                        Submit
                    </Button>
                    <Typography
                        className="padding-side-sm"
                        hidden={pendingBalance <= 0}
                    >
                        Additional Payment Required (
                        <Typography style={{ color: "red", display: "inline" }}>
                            {pendingBalanceDisplay}
                        </Typography>
                        )
                    </Typography>
                </div>
            </div>
        </Dialog>
    );
}
