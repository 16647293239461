import { Container, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { formatAsIntOrNDecimals } from "../../../../../Globals/StringFormatting";
import NumberFormat from "react-number-format";
import React from "react";

interface FinancePageProps {
    materialAndLabourCost: number;
    commissionPercentage: string;
    jobContractTotal: number;
    /*financingOption?: {
        id: number;
        term: number;
        minAmount: number;
        typeId: number;
        fee: number;
        startDate: string;
        endDate: string | null;
        tranCode: string;
        promoParentTranCode: string | null;
        slot: number;
    };*/
    financedAmnt?: number;
    margin: string; // Precomputed margin passed as a string
    finCost: string; // Precomputed financing cost passed as a string
    totalWithoutFinance: string; // Precomputed total without financing passed as a string
    creditCardCharge: string; // Precomputed credit card charge
}

function FinancePage({
    materialAndLabourCost,
    commissionPercentage,
    jobContractTotal,
    finCost,
    totalWithoutFinance,
    creditCardCharge,
}: FinancePageProps) {
    // Calculate Profit
    const profit = jobContractTotal - (parseFloat(finCost) + materialAndLabourCost + parseFloat(creditCardCharge));

    const commissionValue = (parseFloat(commissionPercentage) / 100) * parseFloat(totalWithoutFinance);

    return (
        <Container>
            {/* Financing Cost */}
            <Row style={{ width: "100%" }}>
                <Col className="text-end">
                    <Typography>Fin cost</Typography>
                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${finCost}`}</Typography>
                </Col>
            </Row>

            {/* Total Without Financing */}
            <Row style={{ width: "100%" }}>
                <Col>
                    <Typography>Total w/o Fin</Typography>
                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${totalWithoutFinance}`}</Typography>
                </Col>
            </Row>

            {/* Material and Labour Cost */}
            <Row style={{ width: "100%" }}>
                <Col>
                    <Typography>Material/Labour</Typography>
                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${formatAsIntOrNDecimals(materialAndLabourCost)}`}</Typography>
                </Col>
            </Row>

            {/* Credit Card Charge */}
            <Row style={{ width: "100%" }}>
                <Col>
                    <Typography>C/C Charge</Typography>
                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${creditCardCharge}`}</Typography>
                </Col>
            </Row>

            {/* Profit */}
            <Row style={{ width: "100%" }}>
                <Col>
                    <Typography>Profit</Typography>
                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${formatAsIntOrNDecimals(profit)}`}</Typography>
                </Col>
            </Row>

            {/* Commission Details */}
            <Row>
                <Col>
                    <Row style={{ borderBottom: "1px solid black", paddingBottom: "5px" }}>
                        <Col>
                            <Typography>Commission</Typography>
                        </Col>
                        <Col className="text-end">
                            <Typography>{commissionPercentage}</Typography>
                        </Col>
                    </Row>
                    <Row style={{ borderBottom: "1px solid black", paddingBottom: "5px" }}>
                        <Col>
                            <Typography>Self-Gen</Typography>
                        </Col>
                        <Col className="text-end">
                            <Typography>0%</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography>PromoExtra</Typography>
                        </Col>
                        <Col className="text-end">
                            <Typography>0%</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderLeft: "1px solid black",
                    }}
                >
                    <Typography variant="h5">{`$${formatAsIntOrNDecimals(commissionValue)}`}</Typography>
                </Col>
            </Row>
        </Container>
    );
}

export default FinancePage;
