import { Link, useRouteMatch } from "react-router-dom";
import NavbarPage from "../../../../../Components/Page";
import { useGetAllCommissionArchivedDatesQuery } from "../../../../../generated/graphql";

function CommissionHistory() {
    const { path } = useRouteMatch();
    const { data, loading, error } = useGetAllCommissionArchivedDatesQuery();

    if (loading) return <p>Loading archived dates...</p>;

    if (error) return <p>Error: {error.message}</p>;

    const dates = data?.allCommissionArchivedDates || [];

    return (
        <NavbarPage centerHorizontally title="Archived Commission Dates">
            <h2>Available Archived Dates</h2>
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                {dates.map((dateString: string) => {
                    // Convert date string to something human-readable
                    const displayDate = dateString.split(" ")[0];

                    return (
                        <li key={dateString} style={{ margin: "8px 0" }}>
                            {/* 
                Link to /commission-history/<the date string>.
                We use encodeURIComponent to be safe if the date has spaces.
              */}
                            <Link
                                to={`${path}/commission-history-page/${encodeURIComponent(displayDate)}`}
                                style={{ textDecoration: "none", color: "blue" }}
                            >
                                {displayDate}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </NavbarPage>

    );
}
export default CommissionHistory;