import { useParams } from "react-router";
import NavbarPage from "../../../../../Components/Page";
import { useGetArchivedCurrentCommissionRatesQuery } from "../../../../../generated/graphql";





function CommissionHistoryPage() {

    const { id } = useParams<({id:string})>();

    const archiveDate = decodeURIComponent(id)

     const { data, loading, error } = useGetArchivedCurrentCommissionRatesQuery({
        variables: { date: archiveDate },
        skip: archiveDate == null
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    const history = data?.archivedCurrentCommissionRates || [];


    return (
        <NavbarPage centerHorizontally title="Archived Commission Rates" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem", overflow: "auto" }}>
            <div className="table-fixed-new flex-column align-items-center" style={{ height: "100%", width: "fit-content" }} >
                <table>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Line</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Lower Margin (%)</th>
                            <th style={{ border: "1px solid #ccc", padding: "20px" }}></th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Upper Margin (%)</th>
                            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Rate (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.map((row, index) => (
                            row.id != null ? (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td style={{ textAlign: "center" }}>
                                        {Intl.NumberFormat("en-US", {
                                            style: "percent",
                                            maximumFractionDigits: 0
                                        }).format(row.lowerMargin)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>to</td>
                                    <td style={{ textAlign: "center" }}>
                                        {Intl.NumberFormat("en-US", {
                                            style: "percent",
                                            maximumFractionDigits: 0
                                        }).format(row.upperMargin)}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {Intl.NumberFormat("en-US", {
                                            style: "percent",
                                            maximumFractionDigits: 0
                                        }).format(row.rate)}
                                    </td>
                                </tr>
                            ) : null
                        ))}
                    </tbody>
                </table>
            </div>
        </NavbarPage>
        

    );
}
export default CommissionHistoryPage;