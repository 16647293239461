import { DayRange } from "@hassanmojab/react-modern-calendar-datepicker";
import { Dialog, DialogTitle, DialogContent, Typography, TextField, InputLabel, Select, MenuItem, DialogActions, FormControl } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import { FinancingType, namedOperations, useUpsertFinancingOptionMutation } from "generated/graphql";
import { DateRangeCalendarDialog, dayToIso, dayToMdy } from "Globals/DateAndTimeHelpers";
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from "Globals/GenericValidators";
import _ from "lodash";
import { useState } from "react";
import NumberFormat from "react-number-format";


interface NewFinancingOptionDialogProps {
    setOpen: (newIsOpen: boolean) => void;
    existingTranCodes: string[];
    financingTypes: FinancingType[];
    parentTranCode?: string;
    // only use the default props when for a promo
    defaultTerm?: number;
    defaultAmount?: string;
    defaultTypeId?: number;
    defaultFee?: string;
    defaultSlot?: number;
    defaultDepositAmnt?: number;
    defaultSymbol?: string;
    defaultId?: number;
}

export default function NewFinancingOptionDialog({
    setOpen,
    existingTranCodes,
    financingTypes,
    parentTranCode,
    defaultTerm,
    defaultAmount,
    defaultTypeId,
    defaultFee,
    defaultSlot,
    defaultDepositAmnt,
    defaultSymbol,
    defaultId
}: NewFinancingOptionDialogProps) {
    const isForPromo = isNotNullOrUndefined(parentTranCode);
    
    const [term, setTerm] = useState(defaultTerm?.toFixed(2) ?? '');
    function onChangeTerm(newTerm: string) {
        if (!_.isNaN(+newTerm) || isEmptyString(newTerm)) { setTerm(newTerm.trim()); }
    }

    const [minAmt, setMinAmt] = useState(defaultAmount ?? '');
    function onChangeMinAmt(newMin: string) {
        if (!_.isNaN(+newMin) || isEmptyString(newMin)) { setMinAmt(newMin.trim()); }
    }

    const [fee, setFee] = useState(defaultFee ?? '');
    function onChangeFee(newFee: string) {
        if (!_.isNaN(+newFee) || isEmptyString(newFee)) { setFee(newFee.trim()); }
    }

    const [tranCode, setTranCode] = useState('');
    function onChangeTranCode(newTranCode: string) {
        if ((newTranCode.length <= 3) && (!_.isNaN(+newTranCode) || isEmptyString(newTranCode))) {
            setTranCode(newTranCode.trim());
        }
    }
    const [depositAmnt, setDepositAmnt] = useState(defaultDepositAmnt ?? 0);
    function onChangeDepositAmount(newDepositAmnt: number) {
        if (newDepositAmnt < 0) return
        setDepositAmnt(newDepositAmnt);
    }
    const tranCodeError = existingTranCodes.includes(tranCode);
    
    const [typeId, setTypeId] = useState<number>(defaultTypeId ?? -1);

    const [depositType, setDepositType] = useState<'$' | '%'>('$'); // Default to '$'


    const [slot, setSlot] = useState(defaultSlot ?? 0);

    const [dateRange, setDateRange] = useState<DayRange>({ from: undefined, to: undefined });

    const [promoDateDialogOpen, setPromoDateDialogOpen] = useState(false);

    const [addOption] = useUpsertFinancingOptionMutation({
        refetchQueries: [namedOperations.Query.GetFinancingTableData],
        onError: () => alert("Could not add financing option"),
        // TODO: the reload() is just a quick bandaid solution
        onCompleted: () => {setOpen(false); window.location.reload()}
    });

    function canAddOption() {
        if (isEmptyString(term)) {
            alert("Enter the financing term");
            return false;
        }

        //if (isEmptyString(tranCode) || tranCode.length !== 3 || parentTranCode?.length!==3) {
        //    alert("Enter a 3 digit tran code");
        //    return false;
        //}

        if (tranCodeError) {
            alert("An option with this tran code already exists - return to the table to update it");
            return false;
        }

        if (isEmptyString(minAmt)) {
            alert("Enter the minimum amount for the option");
            return false;
        }

        if (typeId === -1) {
            alert("Please specify what type of financing option this is.");
            return false;
        }

        if (isEmptyString(fee)) {
            alert("Enter a fee percentage");
            return false;
        }

        if (depositAmnt < 0) {
            alert("Please Enter a positive number")
        }
        

        if (isForPromo) {
            if (isNullOrUndefined(dateRange.from)) {
                alert("Enter the date the promo will begin");
                return false;
            } else if (isNullOrUndefined(dateRange.to)) {
                alert("Choose the date the promo will end")
                return false;
            }
        }

        return true;
    }

    function onSubmit() {
        if (canAddOption()) {
            addOption({
                variables: {
                    option: {
                        id: defaultId??-1,
                        term: +term,
                        minAmount: +minAmt,
                        typeId: typeId,
                        fee: +fee,
                        tranCode: tranCode ?? parentTranCode,
                        slot: slot,
                        startDate: dateRange.from ? dayToIso(dateRange.from) : null,
                        endDate: dateRange.to ? dayToIso(dateRange.to) : null,
                        promoParentTranCode: parentTranCode ?? null,
                        depositAmount: depositAmnt ?? 0,
                        symbol: depositType ??"$"
                    }
                }
            });
        }
    }

    return (
        <>
            <Dialog open={true}>
                <DialogTitle>Add {isForPromo ? "Promo" : "Financing"} Option</DialogTitle>

                <DialogContent>
                    {isForPromo && (
                        <div className="margin-bottom-sm" style={{maxWidth: "14rem"}}>
                            <Typography>Adding promo based on option with tran code <b>{parentTranCode}</b></Typography>
                        </div>
                    )}

                    {tranCodeError && (
                        <div className="margin-bottom-sm" style={{maxWidth: "14rem"}}>
                            <Typography 
                                className="error-text"
                            >Option with tran code {tranCode} already exists. Return to the table to edit the option.</Typography>
                        </div>
                    )}

                    <div className="flex-row flex-gap-md">
                        <div className="flex-column flex-gap-sm">
                            <TextField
                                label="Term"  className="w-6r"
                                value={term} onChange={e => onChangeTerm(e.target.value)}
                                disabled={isForPromo}
                            />
                            
                            <TextField
                                label="Amount ($)" className="w-6r"
                                value={minAmt} onChange={e => onChangeMinAmt(e.target.value)} 
                            />
                            
                            <NumberFormat
                                value={fee} onValueChange={(v) => onChangeFee(v.value)}
                                customInput={TextField}
                                label="Fee"
                                className="w-6r"
                                displayType="input" allowNegative={false}
                                suffix="%"
                                decimalScale={2}
                            />

                            {isForPromo && (
                                <TextField
                                    className="w-6r"
                                    label="Start Date"
                                    InputProps={{ readOnly: true }}
                                    onClick={() => setPromoDateDialogOpen(true)}
                                    value={dateRange.from ? dayToMdy(dateRange.from) : ''}
                                />
                            )}
                            {isForPromo &&(
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <TextField
                                    label={`Deposit (${depositType})`} // Dynamic label
                                    className="w-6r"
                                    value={depositAmnt}                                    
                                    onChange={(e) => onChangeDepositAmount(Number(e.target.value))}
                                />
                                <FormControl style={{ width: "80px" }}>
                                    <Select
                                        value={depositType}                                        
                                        onChange={(e) => setDepositType(e.target.value as '$' | '%')}
                                    >
                                        <MenuItem value="$">$</MenuItem>
                                        <MenuItem value="%">%</MenuItem>
                                    </Select>
                                </FormControl>
                                </div>
                            )}

                        </div>

                        <div className="flex-column flex-gap-sm">
                            <TextField
                                label="Tran Code"
                                value={parentTranCode}
                                onChange={e => onChangeTranCode(e.target.value)}
                                className="w-6r"
                                error={tranCodeError}
                                disabled={isForPromo }
                            />
                            
                            <div className="flex-column">
                                <FormControl disabled={isForPromo}>
                                    <InputLabel>Type</InputLabel>
                                    <Select value={typeId} onChange={e => setTypeId(e.target.value as number)} className="w-6r">
                                        {financingTypes.map(type => <MenuItem value={type.id} key={`type-${type.id}`}>{type.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            
                            <div className="flex-column">
                                <FormControl>
                                    <InputLabel>Slot</InputLabel>
                                    <Select value={slot} onChange={e => setSlot(e.target.value as number)} className="w-6r">
                                        <MenuItem value={0}>Unassigned</MenuItem>
                                        {[1, 2, 3].map(s => <MenuItem key={`slot-menu-${s}`} value={s}>{s}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>

                            {isForPromo && (
                                <TextField
                                    className="w-6r"
                                    label="End Date"
                                    InputProps={{readOnly: true}}
                                    onClick={() => setPromoDateDialogOpen(true)}
                                    value={dateRange.to ? dayToMdy(dateRange.to!) : ''}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className="margin-top-xsm">
                    <SpacedButton className='cancel-button' onClick={() => setOpen(false)}>Cancel</SpacedButton>
                    <SpacedButton variant="contained" color='secondary' onClick={onSubmit}>Submit</SpacedButton>
                </DialogActions>
            </Dialog>

            {promoDateDialogOpen && 
                <DateRangeCalendarDialog
                    open={true} setOpen={setPromoDateDialogOpen}
                    selectedRange={dateRange} setSelectedRange={setDateRange}
                />
            }
        </>
    )
}