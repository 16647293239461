import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { DialogActions } from "@mui/material";
import SpacedButton from "../../../../../Components/Forms/Controls/SpacedButton";
import FlatDatePicker from "../../../../../FlatComponents/Visual/FlatDatePicker";
import _ from "lodash";
import { useState } from "react";
import { namedOperations, useAddCommissionRateMutation } from "../../../../../generated/graphql";
import { isEmptyString } from "../../../../../Globals/GenericValidators";

interface NewCurrentCommissionRatesProps {
    setOpen: (newIsOpen: boolean) => void;
}
export default function NewCurrentCommissionRates({ setOpen }: NewCurrentCommissionRatesProps) {
    const currentDate = new Date().toLocaleDateString();
    const [lowerMargin, setLowerMargin] = useState('');
    function onChangeLowerMargin(newLowerMargin: string) {
        if (!_.isNaN(+newLowerMargin) || isEmptyString(newLowerMargin)) { setLowerMargin(newLowerMargin.trim()); }
    }

    const [upperMargin, setUpperMargin] = useState('');
    function onChangeUpperMargin(newUpperMargin: string) {
        if (!_.isNaN(+newUpperMargin) || isEmptyString(newUpperMargin)) { setUpperMargin(newUpperMargin.trim()); }
    }

    const [rate, setRate] = useState('');
    function onChangeRate(newRate: string) {
        if (!_.isNaN(+newRate) || isEmptyString(newRate)) { setRate(newRate.trim()); }
    }

    const [addOption] = useAddCommissionRateMutation({
        refetchQueries: [namedOperations.Query.GetCurrentCommissionRates],
        onError: () => alert("Could not load Current Commissions Rate "),
        onCompleted: () => { setOpen(false); window.location.reload() }
    });

    function canAddOption() {
        if (isEmptyString(lowerMargin) || +lowerMargin < 0 || +lowerMargin > 100) {
            alert("Enter a valid lower margin (0-100)");
            return false;
        }
        if (isEmptyString(upperMargin) || +upperMargin < 0 || +upperMargin > 100) {
            alert("Enter a valid upper margin (0-100)");
            return false;
        }
        if (isEmptyString(rate) || +rate < 0 || +rate > 100) {
            alert("Enter a valid rate (0-100)");
            return false;
        }
        return true;
    }

    function onSubmit() {
        if (canAddOption()) {
            addOption({
                variables: {
                    input: {
                        lowerMargin: +lowerMargin/100,
                        upperMargin: +upperMargin/100,
                        rate: +rate/100,
                        creationDate: currentDate
                    }
                }
            })

        }
    }

    return (
        <>
            <Dialog open={true}>
                <DialogTitle>Add New Rate</DialogTitle>

                <DialogContent>
                    <div className="flex-row flex-gap-md">
                        <div className="flex-column flex-gap-sm">
                            <TextField
                                label="L-Margin" className="w-6r"
                                value={lowerMargin} onChange={e => onChangeLowerMargin(e.target.value)}
                                
                            />
                            <TextField
                                label="U-Margin" className="w-6r"
                                value={upperMargin} onChange={e => onChangeUpperMargin(e.target.value)}
                            />

                        </div>
                        <div className="flex-column flex-gap-sm">
                            <TextField
                                label="Rate" className="w-6r"
                                value={rate} onChange={e => onChangeRate(e.target.value)}
                            />
                            <TextField
                                label="Date"
                                className="w-6r"
                                value={currentDate}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </div>
                    </div>
                    

                </DialogContent>

                <DialogActions>
                    <SpacedButton className='cancel-button' onClick={() => setOpen(false)}>Cancel</SpacedButton>
                    <SpacedButton variant="contained" color='secondary' onClick={onSubmit }>Submit</SpacedButton>
                </DialogActions>
            </Dialog>

            
        </>
    );
}