import { Container, Typography } from "@material-ui/core";
import { Row, Col } from 'react-bootstrap';
import MaterialLaborCommisionBreakdown from "./SalesCommission/MaterialLaborCommisionBreakdown";
import { useParams } from "react-router-dom";
import {
    useGetCommisionHeaderDetailsQuery,
    useGetContractIdQuery,
    useGetJobContractQuery,
    useGetJobInvoiceDataQuery,
    useGetJobPaymentsQuery,
    useGetPaymentsPaneInfoQuery,
    useGetPricingSummaryQuery
} from "../../../../generated/graphql";
import HeaderCommisionPage from "./SalesCommission/HeaderCommissionPage";
import CustomerNamePage from "./SalesCommission/CustomerNamePage";
import FinancePage from "./SalesCommission/FinancePage";
import React from "react";

export default function QuotePage() {
    const { id1: id } = useParams<{ id1: string }>();
    const jobConfigId = parseInt(id, 10);

    const { data, loading, error } = useGetCommisionHeaderDetailsQuery({ variables: { jobConfigurationId: jobConfigId }, skip: !jobConfigId });
    const { data: invoiceData, loading: loadingInvoiceData, error: errorInvoiceData } = useGetJobInvoiceDataQuery({ variables: { jobConfigurationId: jobConfigId }, skip: !jobConfigId });
    const { data: contractIdData } = useGetContractIdQuery({ variables: { jobConfigurationId: jobConfigId }, skip: !jobConfigId });

    let contractId = contractIdData?.contractId ?? -1;

    const { data: pricingData, loading: loadingPricingData, error: errorData } = useGetPricingSummaryQuery({ variables: { jobConfigurationId: jobConfigId }, skip: !jobConfigId });
    const { data: contractData, loading: loadingContract, error: errorContact } = useGetJobContractQuery({ variables: { jobContractId: contractId }, skip: contractId === -1 });
    const { data: paymentsInfo } = useGetPaymentsPaneInfoQuery({ variables: { jobConfigurationId: jobConfigId, jobContractId: contractId }, skip: !jobConfigId });
    const { data: jobPayment } = useGetJobPaymentsQuery({ variables: { jobContractId: contractId }, skip: !contractId });

    if (loading || loadingPricingData || loadingContract || loadingInvoiceData) return <p>Loading...</p>;
    if (error || errorData || errorContact || errorInvoiceData) {
        return (
            <div>
                <p>Error loading data:</p>
                <ul>
                    {error && <li>{error.message}</li>}
                    {errorData && <li>{errorData.message}</li>}
                    {errorContact && <li>{errorContact.message}</li>}
                    {errorInvoiceData && <li>{errorInvoiceData.message}</li>}
                </ul>
            </div>
        );
    }

    const isDataMissing = !data?.jobConfigurationHeader || !pricingData?.jobConfigurationCost || !contractData?.jobContract?.totalPrice;
    if (isDataMissing) return <p>No data available</p>;

    const { jobConfigurationHeader } = data;
    const customer = jobConfigurationHeader.customer;
    const salespersons = jobConfigurationHeader.assignedSalespeople;
    const SahNumber = jobConfigurationHeader.appointmentNumber;

    const materialAndLabourCost = pricingData?.jobConfigurationCost;
    const jobContractTotal = contractData.jobContract.totalPrice;
    const financedAmnt = paymentsInfo?.financedAmount;
    const jobPayments = jobPayment?.jobPayments;
    const financingOption = invoiceData?.jobConfiguration.financing?.financingOption;


    // Calculate Credit Card Charge
    const creditCardCharge = jobPayments
        ? jobPayments
            .filter(payment => payment.paymentMethodId === 3) // Filter credit card payments
            .reduce((total, payment) => total + payment.amount, 0) * 0.035 // Apply 3.5% fee
        : 0;


    // Calculate Financing Cost
    const finCost = financingOption && financedAmnt
        ? (financingOption.fee / 100) * financedAmnt
        : 0;

    // Calculate Total Without Financing
    const totalWithoutFinance = financingOption
        ? jobContractTotal - finCost
        : jobContractTotal;

    // Custom Margin Calculation
    const calculateCustomMargin = (
        contractTotal: number,
        materialAndLabour: number,
        ccCharge: number,
        finCost: number,
        totalWithoutFinance: number,
        hasFinancing: boolean
    ): number => {
        const denominator = hasFinancing ? totalWithoutFinance : contractTotal;
        if (denominator === 0) return 0; // Avoid division by zero
        const margin = (contractTotal - (materialAndLabour + ccCharge + finCost)) / denominator;
        return margin * 100; // Convert to percentage
    };

    const margin = (() => {
        const hasFinancing = Boolean(financingOption);
        const creditCardCharge = 0; // Modify if dynamic
        return calculateCustomMargin(
            jobContractTotal,
            materialAndLabourCost,
            creditCardCharge,
            finCost,
            totalWithoutFinance,
            hasFinancing
        );
    })();

    // Determine Commission Percentage
    const commissionPercentage = (() => {
        const rate = pricingData?.currentCommissionRates?.find(rate =>
            margin / 100 >= rate.lowerMargin && margin / 100 < rate.upperMargin
        )?.rate || 0;
        return rate * 100; // Convert to percentage
    })();

    return (
        <>
            <div>
                <HeaderCommisionPage
                    salespeople={salespersons}
                    commissionPercentage={`${commissionPercentage.toFixed(2)}%`}
                    jobContractTotal={totalWithoutFinance}
                />
                <hr className="hr-separator" />
                <CustomerNamePage
                    financingTerm={financingOption?.term}
                    jobContractTotal={jobContractTotal}
                    customer={customer}
                    SahNumber={SahNumber}
                />
                <hr className="hr-separator" />
                <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col>
                        <FinancePage
                            materialAndLabourCost={materialAndLabourCost}
                            commissionPercentage={`${commissionPercentage.toFixed(0)}%`}
                            jobContractTotal={jobContractTotal}
                            //financingOption={financingOption}

                            financedAmnt={financedAmnt}
                            margin={margin.toFixed(2)}
                            finCost={finCost.toFixed(2)}
                            totalWithoutFinance={totalWithoutFinance.toFixed(2)}
                            creditCardCharge={creditCardCharge.toFixed(2)}
                        />
                    </Col>
                </Row>
            </div>
            <div>
                <MaterialLaborCommisionBreakdown jobConfigId={jobConfigId} />
            </div>
        </>
    );
}
