import { Button, Typography } from "@material-ui/core";
import FlatSection from "../../../../../../FlatComponents/Layout/FlatSection";
import { formatAsIntOrNDecimals } from "../../../../../../Globals/StringFormatting";
import { CommissionBreakdownTableProps, makeCommissionMaterialBreakdownRows } from "./TableUtils";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import TableModal from "./TableModal";

interface EditButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: React.ElementType;
}

export default function MaterialTable({ title, areaBreakdownsForCommission }: CommissionBreakdownTableProps) {
    const [modalState, setModalState] = useState<{
        show: boolean;
        areaId?: number;
        lineNo?: number;
        jobConfigId?: number;
        title?: string;
        misc?: string;
        miscAmount?: number;
    }>({ show: false });

    const [miscRows, setMiscRows] = useState(makeCommissionMaterialBreakdownRows(areaBreakdownsForCommission));

    const handleEditClick = (data: any) => {
        setModalState({ ...data, show: true });
    };

    const handleClose = () => {
        setModalState({ show: false });
    };

    // Function to handle adding a new row
    const handleModalSave = (newMisc: { misc: string; miscAmount: number; areaId?: number; lineNo?: number; jobConfigId?: number }) => {
        setMiscRows(prevRows => [
            ...prevRows,
            {
                productType: "Miscellaneous",
                lineNo: newMisc.lineNo ?? prevRows.length + 1,
                misc: newMisc.misc,
                miscAmount: newMisc.miscAmount,
                shippingIdentifier: true,
                totalCost: 0,
                unitCost: 0,
                totalSqft: 0,
                areaId: newMisc.areaId,
                jobConfigId: newMisc.jobConfigId,
            },
        ]);
        setModalState({ show: false });
    };

    if (miscRows.length <= 0)
        return <Typography>No area or product information to show</Typography>;

    return (
        <FlatSection centerAdornment={title ?? "Materials"} className="flat-job-detail-table" removePadding={true}>
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}></th>
                        <th className="px-2">QTY</th>
                        <th className="text-center">Product</th>
                        <th className="text-center px-3">Unit Cost</th>
                        <th className="px-2">Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {miscRows.map((row, index) => (
                        // Existing Misc Rows: No Edit Icon
                        row.shippingIdentifier && row.misc ? (
                            <>
                            <tr key={`misc-${index}`}>
                                <td>{row.line}</td>
                                <td>Misc</td>
                                <td colSpan={3}>{row.misc}</td>
                                <td>${row.miscAmount ?? 0}</td>
                                <td></td> {/* No edit button for existing rows */}
                                </tr>
                            </>
                        ) : (
                            // Regular Rows
                            <tr key={index}>
                                <td className="p-1">{row.line}</td>
                                <td>Material</td>
                                <td>{formatAsIntOrNDecimals(row.totalSqft)}</td>
                                <td>{row.productType} {row.style}-{row.color}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.unitCost)}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.totalCost)}</td>
                                <td></td>
                            </tr>
                        )
                    ))}

                    {/* Dummy Row for Adding New Misc */}
                    <tr key="add-misc">
                        <td>+</td>
                        <td>Misc</td>
                        <td colSpan={3}>Add new miscellaneous entry</td>
                        <td>--</td>
                        <td className="text-end p-0 m-0">
                            <EditButton onClick={() => handleEditClick({ title: "Add Misc", misc: "", miscAmount: 0 })} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <TableModal
                show={modalState.show}
                handleClose={handleClose}
                title={modalState.title}
                areaId={modalState.areaId}
                lineNo={modalState.lineNo}
                jobConfigId={modalState.jobConfigId}
                onSave={handleModalSave} // Pass function to save modal data
            />
        </FlatSection>
    );
}

const EditButton = ({ onClick, icon: Icon = EditIcon }: EditButtonProps) => (
    <Button size="small" onClick={onClick}>
        <Icon fontSize={"small"} />
    </Button>
);
